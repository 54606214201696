/** @jsx jsx */
import React, { useEffect, useReducer } from "react"

import Amplify from "@aws-amplify/core";
import Analytics from '@aws-amplify/analytics';
import { Auth, API, graphqlOperation, Cache/*Hub, Logger*/ } from "aws-amplify"
import { Picker, S3Image } from 'aws-amplify-react';
import Storage from '@aws-amplify/storage'

import { AmplifyS3ImagePicker } from "@aws-amplify/ui-react";
import {v4 as uuid} from 'uuid';
import { /*Divider,  Grid, Header, Input, List, Segment, Icon, Image, Dropdown, Menu, Label, Reveal,*/ Form as SemanticForm, Button as SemanticButton} from 'semantic-ui-react'


import { getAssetDeltaObject, getLegacyShotwireAsset, assetDeltaUpdate } from "../graphql/videoPlayerQueries"
import { getShotwireAssetsDeltaPublic  } from "../graphql/shotassetQueries"
import { getShotwireAssetsDelta, getShotwireAlbum, getShotwirePhoto, listPhotosByAlbum } from "../graphql/queries"
import { createShotwireAssetsDelta, createShotwirePhoto } from "../graphql/mutations"
import { onUpdateShotwirePhoto } from "../graphql/subscriptions"


import Helmet from 'react-helmet'

import {loadStripe} from '@stripe/stripe-js';

import { Box, jsx } from 'theme-ui'
import { Link, navigate } from 'gatsby'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import Image from 'react-bootstrap/Image'
import Form from 'react-bootstrap/Form'
import Spinner from 'react-bootstrap/Spinner'
import Modal from 'react-bootstrap/Modal'
import ModalHeader from 'react-bootstrap/ModalHeader'
import ModalTitle from 'react-bootstrap/ModalTitle'
import ModalBody from 'react-bootstrap/ModalBody'
import ModalFooter from 'react-bootstrap/ModalFooter'
import styled from '@emotion/styled'
import InfoCard from '../@geocine/gatsby-theme-organization/components/InfoCard'
import Layout from '../@geocine/gatsby-theme-organization/components/Layout'

import PhotoCard from '../@geocine/gatsby-theme-organization/components/PhotoCard'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faVideo, faDownload, faCartPlus, faCartArrowDown, faHome, faEnvelope, faPhone, faPlay, faPause, faSpinner, faVideoSlash, faHeart, faStar, faPiggyBank, faPlus, faMinus, faCheck, faCoins, faLink } from "@fortawesome/free-solid-svg-icons";

import ReactPlayer from 'react-player'
// import ReactJWPlayer from 'react-jw-player';
import ReactJWPlayer from '../@geocine/gatsby-theme-organization/components/JwVideoPlayer'

// import Layout from "../components/layout"
// import SEO from "../components/seo"


// import CoinbaseCommerceButton from 'react-coinbase-commerce';
// import 'react-coinbase-commerce/dist/coinbase-commerce-button.css';

import 'src/css/theme.scss'
import favicon from '../@geocine/gatsby-theme-organization/assets/favicon.ico'
import '@fortawesome/fontawesome-svg-core/styles.css'
import { config } from '@fortawesome/fontawesome-svg-core'

import awsconfig from "../aws-exports";
import { AppsContext } from '../AppsProvider'

// import { Authenticator, SignIn, SignUp, ConfirmSignUp, Greetings } from 'aws-amplify-react';


Amplify.configure(awsconfig);
// Prevent fontawesome from dynamically adding its css since we did it manually above
config.autoAddCss = false

// const analyticsConfig = {
//   AWSPinpoint: {
//         // Amazon Pinpoint App Client ID
//         appId: '12039a8ccae04f7fab5cbff6eb095368',
//         // Amazon service region
//         region: 'us-east-1',
//         mandatorySignIn: false,
//   }
// }

// Analytics.configure(analyticsConfig)

var stripePromise, checkoutSessionId

const WatermarkLogoDiv = styled('div')`
  position: absolute;
  top: 20px;
  left: 35px;
  opacity: 0.6;
  display:none;
`
 
const UserOpsDiv = styled('div')`
  
  top: 10px;
  left: 0px;
  
`

// const MySectionHeader = Object.assign({}, AmplifyTheme.sectionHeader, { background: 'orange' });
// const MyTheme = Object.assign({}, AmplifyTheme, { sectionHeader: MySectionHeader });
const MyTheme = {
    signInButtonIcon: { 'display': 'none' },
    googleSignInButton: { 'backgroundColor': 'red', 'borderColor': 'red' },
    button: { 'backgroundColor': 'red', 'borderColor': 'red' },
    navButton: { 'backgroundColor': 'red', 'borderColor': 'red' }


}

const MetaEditable = ['caption', 'headline', 'credit', 'byline', 'keywords', 'suppCat', 'city', 'state', 'country']

const categoryMap = {
  CelebrityCandids:'Celebrity Candids',
  RedCarpetEvents:'Red Carpet',
  News:'News',
  Sports: 'Sports',
  Music: 'Music',
  Fashion: 'Fashion',
  Travel: 'Travel',
  Arts: 'Arts',
  Nature: 'Nature',
  Aerial: 'Aerial',
  CelebrityInterviews:'Celebrity Interviews'
}

const formArchivedMap = {
  "false":'Not Published / Archived',
  "true":'Published / Archived ',
}

const formMarketplaceMap = {
  "false":'Private',
  "true":'Licensing Available',
}

var setupElements = async function() {
  stripePromise = await loadStripe("pk_live_xVFKV4FGy3ycA15Ft5vPBZDl");
};


const PhotoSetView = ({location}) => {
  // console.log('location:', location)
  let userInfo = null, 
      uRoles = null, 
      uGroups = null, 
      uIdentityId = null,
      metaForm = {}, 
      searchParams, 
      fileSize, 
      srcMediainfo, 
      mediainfo = null, 
      updateMediainfo = false, 
      cart = [],
      myDownloads = [],
      favs = [],
      modifiedThumbPlyr = false,
      uploader = {},
      photos = [],
      hasMorePhotos = true,
      fetchingPhotos = false,
      nextPhotosToken = null,
      privacyLevel = 'protected',
      zipLoading = false,
      photoUploader = {},
      photoAlbum = {}





      
  // console.log('location.state:',location.state)
    // console.log('location.search:',location.search)

  // let playerDiv = React.createRef()
   
  // const [state, dispatch] = useReducer(reducer, initialState);
  const appsContext = React.useContext(AppsContext)
  
  let vPlayer = appsContext.state.videoPlayer 
  // console.log('vPlayer:', vPlayer)

  // myDownloads = Cache.getItem('myDownloads')
  // cart = Cache.getItem('shopCart')
  const expiration = new Date(2020, 12, 18)

  const todayDate = new Date()
  const nowYr = todayDate.getFullYear()
  const nowMo = todayDate.getMonth()+1
  const nowDay = todayDate.getDate()
  
  // console.log('yyyy, mo, dd', nowYr ,  nowMo+1 , nowDay)

  // if(appsContext.state.user){
  //   const  userInfo  = appsContext.state.user
  //   // console.log('userInfo: ', userInfo)

  //   const uRoles = userInfo.attributes[`custom:roles`]
  //   // console.log('uRoles: ', uRoles)
  // }


// const downloadRandomImage = () => {
//   fetch('http://localhost:8080/api/files')
//     .then(response => {
//       const filename =  response.headers.get('Content-Disposition').split('filename=')[1];
//       response.blob().then(blob => {
//         let url = window.URL.createObjectURL(blob);
//         let a = document.createElement('a');
//         a.href = url;
//         a.download = filename;
//         a.click();
//     });
//  });
// }



// const S3ImageUpload = (props) => {
//   // const [uploading, setUploading] = useState(false)
//   const uploading = appsContext.state.photoUploader.isUploading
  
//   const uploadFile = async (file) => {
//     console.log('file:',file)
//     const fileName = 'upload/'+uuid();
//     const user = await Auth.currentAuthenticatedUser();
//     console.log('fileName:', fileName)
//     console.log('user:', user)
//     try{
//       const result = await Storage.vault.put(
//         fileName, 
//         file, 
//         {
//           metadata: {
//             albumid: props.albumId,
//             owner: user.username,
//             name: file.name
//           }
//         }
//       );
//       console.log('Uploaded file: ', result);
//     }catch(e){
//       console.log('upload error:', e)
//     }

//     // Storage.vault.put(
//     //     fileName, 
//     //     file, 
//     //     {
//     //       metadata: {
//     //         albumid: props.albumId,
//     //         owner: user.username,
//     //         name: file.name
//     //       }
//     //     }
//     //   ).then(result => console.log('vault result:',result))
//     //   .catch(err => console.log('vault err:',err));

    
//   }

//   const onChange = async (e) => {
//     // setUploading(true)
//     appsContext.dispatch({'type':"PHOTOUPLOADER",isUploading:true})
    
//     let files = [];
//     for (var i=0; i<e.target.files.length; i++) {
//       files.push(e.target.files.item(i));
//     }
//     await Promise.all(files.map(f => uploadFile(f)));

//     // setUploading(false)
//     appsContext.dispatch({'type':"PHOTOUPLOADER",isUploading:false})

//   }

//   return (
//     <div>
//       <Form.Button
//         onClick={() => document.getElementById('add-image-file-input').click()}
//         disabled={uploading}
//         icon='file image outline'
//         content={ uploading ? 'Uploading...' : 'Add Images' }
//       />
//       <input
//         id='add-image-file-input'
//         type="file"
//         accept='image/*'
//         multiple
//         onChange={onChange}
//         style={{ display: 'none' }}
//       />
//     </div>
//   );
// }

const uploadFile = async (file) => {
    console.log('file:',file)
    const fileName = 'upload/'+uuid();
    const user = await Auth.currentAuthenticatedUser();
    console.log('fileName:', fileName)
    console.log('user:', user)
    try{
      const result = await Storage.vault.put(
        fileName, 
        file, 
        {
          metadata: {
            albumid: appsContext.state.photoAlbum.id,
            owner: user.username,
            name: file.name
          }
        }
      );
      console.log('Uploaded file: ', result);
    }catch(e){
      console.log('upload error:', e)
    }

    // Storage.vault.put(
    //     fileName, 
    //     file, 
    //     {
    //       metadata: {
    //         albumid: props.albumId,
    //         owner: user.username,
    //         name: file.name
    //       }
    //     }
    //   ).then(result => console.log('vault result:',result))
    //   .catch(err => console.log('vault err:',err));

    
  }

 

  const handleOnchange = (e) => {
    // console.log('e.target.id:',e.target.id)
    // console.log('e.target.value:',e.target.value)
    if(e.target.id==='setDateYear'){
      // console.log('year')
      // console.log('e.target.value:',e.target.value)
      appsContext.dispatch({type:'METAFORM', metaForm: { ...appsContext.state.metaForm, yr: e.target.value} })
    }else if(e.target.id==='setDateMonth'){
      // console.log('month')
      // console.log('e.target.value:',e.target.value)
      appsContext.dispatch({type:'METAFORM', metaForm: { ...appsContext.state.metaForm, mo: e.target.value} })
    }else if(e.target.id==='setDateDay'){
      // console.log('day')
      // console.log('e.target.value:',e.target.value)
      appsContext.dispatch({type:'METAFORM', metaForm: {...appsContext.state.metaForm, day: e.target.value} })
    }else if(e.target.id==='category'){
      // console.log('e.target.value:',e.target.value)
      appsContext.dispatch({type:'METAFORM', metaForm: {...appsContext.state.metaForm, category: e.target.value} })
    }else if(e.target.id==='archived'){
      // console.log('e.target.value:',e.target.value)
      appsContext.dispatch({type:'METAFORM', metaForm: {...appsContext.state.metaForm, archived: e.target.value} })
    }else if(e.target.id==='marketplace'){
      // console.log('e.target.value:',e.target.value)
      appsContext.dispatch({type:'METAFORM', metaForm: {...appsContext.state.metaForm, marketplace: e.target.value} })
    }


  }

  const handleMetaChange = (e) => {
    // console.log('e.target.id:',e.target.id)
    // console.log('e.target.value:',e.target.value)
    // console.log('metaField:',metaField)
    
    // const metaForm = {}
    // metaForm[e.target.id] = e.target.value
    // console.log('metaForm::', metaForm)
    appsContext.dispatch({type:'METAFORM', metaForm: {...appsContext.state.metaForm, [e.target.id]:e.target.value} })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    e.persist()
    appsContext.dispatch({ type: "METAUPDATE", isMetaSaving: true })
    // console.log('Submit target:',e.target)
    // console.log('Submit target.category:',e.target.headline.value)
    // console.log('appsContext.state.videoPlayer.assetObj:', appsContext.state.videoPlayer.assetObj)
    // console.log('appsContext.state.videoPlayer.assetObj.srcMediainfo:', appsContext.state.videoPlayer.assetObj.srcMediainfo)

    const assetObj = {
      id: appsContext.state.videoPlayer.assetObj.id,
      archived: e.target.archived.value,
      category: e.target.category.value,
      setDate: e.target.setDateYear.value + e.target.setDateMonth.value + e.target.setDateDay.value,
      headline: e.target.headline.value,
      caption: e.target.caption.value,
      byline: e.target.byline.value,
      keywords: e.target.keywords.value,
      suppCat: e.target.suppCat.value,
      city: e.target.city.value,
      country: (typeof e.target.country !== 'undefined') ? e.target.country.value : '' ,
      marketplace: e.target.marketplace.value,
      state: (typeof e.target.state !== 'undefined') ? e.target.state.value : '',
    }

    // console.log('appsContext.state.videoPlayer.assetObj.video_length: ', appsContext.state.videoPlayer.assetObj.video_length)

    if(appsContext.state.videoPlayer.assetObj.srcMediainfo === null){
        // console.log('retrieve & update video info...')
          // appsContext.dispatch({ type: "SRCMEDIAINFOUPDATE", isSrcMediaInfoUpdating: true })
              // console.log('appsContext.state.videoPlayer: ', appsContext.state.videoPlayer.assetObj.fileName)
              // return filename
              const OgSrcVideoJobInfo = await API.get('shotwireAPI', '/createVideoProxy', {
                              'queryStringParameters': {
                                // 'setId': appsContext.state.videoPlayer.assetObj.realSetId,
                                // 'setDate': appsContext.state.videoPlayer.assetObj.setDate,
                                // 'setName': appsContext.state.videoPlayer.assetObj.name,
                                // 'assetName': (dlType === 'hrdl') ? appsContext.state.videoPlayer.assetObj.highRezFileName : appsContext.state.videoPlayer.assetObj.highRezFileName.replace('.mp4','-h264.mp4'),
                                'jobAction': 'OgSrcVideoJobInfo',
                                // 's3srcUrl' : srcObj.srcUrl,
                                'ogSrcFile' : appsContext.state.videoPlayer.assetObj.fileName,
                                // 'jobTemplate': 'Shotwire-Custom-Job-User-'+ appsContext.state.user.username//'Shotwire-Watermarked-Proxies'                      
                              }
                            });
              // console.log('OgSrcVideoJobInfo:', JSON.stringify(OgSrcVideoJobInfo.OgSrcVideoJobInfo[0].srcMediainfo) )
              // console.log('OgSrcVideoJobInfo.OgSrcVideoJobInfo[0].srcMediainfo:', JSON.parse(OgSrcVideoJobInfo.OgSrcVideoJobInfo[0].srcMediainfo) )
              mediainfo = JSON.parse(OgSrcVideoJobInfo.OgSrcVideoJobInfo[0].srcMediainfo)
              // console.log('mediainfo:', mediainfo )
              // return OgSrcVideoJobInfo.OgSrcVideoJobInfo
              if(OgSrcVideoJobInfo.OgSrcVideoJobInfo.length>0){                            
           
                  assetObj.srcMediainfo= OgSrcVideoJobInfo.OgSrcVideoJobInfo[0].srcMediainfo
                  updateMediainfo = true
                  
              }
      
    }else if(!(appsContext.state.videoPlayer.assetObj.video_length>0)){
          // console.log('update video info...')

            mediainfo = JSON.parse(appsContext.state.videoPlayer.assetObj.srcMediainfo)
            // console.log('mediainfo:', mediainfo )
            updateMediainfo = true
          


    }

    if(updateMediainfo===true){
        assetObj.video_length= mediainfo.container.duration/1000
        assetObj.fileSize= mediainfo.container.fileSize/1024/1024
        assetObj.video_width= mediainfo.video[0].width
        assetObj.video_height= mediainfo.video[0].height
        assetObj.video_aspect= mediainfo.video[0].aspectRatio
        assetObj.video_frames= mediainfo.video[0].frameCount
          // video_format: String,
        assetObj.video_bitrate= mediainfo.video[0].bitrate
        assetObj.video_fps= mediainfo.video[0].framerate 
        
        if(typeof mediainfo.audio !== 'undefined'){
          assetObj.audio_codec= mediainfo.audio[0].codec
          // audio_format: mediainfo.audio[0].format,
          assetObj.audio_bitrate= mediainfo.audio[0].bitrate
          assetObj.audio_rate= mediainfo.audio[0].samplingRate
          assetObj.audio_length= mediainfo.audio[0].duration
        }
    }


    const eventDate = new Date(e.target.setDateYear.value +'-'+ e.target.setDateMonth.value  +'-'+ e.target.setDateDay.value).getTime()
    // console.log('eventDate:', eventDate)
    // console.log('assetObj:', assetObj)
  if(typeof e.target.forceUnpublish != 'undefined'){
    // console.log('e.target.forceUnpublish.value:',e.target.forceUnpublish.value)
    if(e.target.forceUnpublish.value == 'on'){
      // console.log('updating asset.')
      updateAssetDelta(assetObj)
    }else{
      // console('abort save')
    }
   
  }else{
    // console.log('updating asset..')
    updateAssetDelta(assetObj)
  }
  
    
    

  }

  const handleClick = async (e, arg) => {
    
    if (e){ 
      e.preventDefault();      

      if(arg === 'addToCart'){
          // console.log('add to cart...')
          cart = Cache.getItem('shotwire-shopCart')
          // console.log('cart:', cart)

          const expiration = new Date(2020, 12, 18)
        
        if(cart == null){
          // console.log('set item...')
          Cache.setItem('shotwire-shopCart', [ appsContext.state.videoPlayer.assetObj.id ], { expires: expiration.getTime() });
          appsContext.dispatch({type:"SHOPCART", shopCart:[ appsContext.state.videoPlayer.assetObj.id ]})
          // document.getElementById("fav_"+appsContext.state.videoPlayer.assetObj.assetId).style.color = "red";
        }else{
          
          if(!cart.includes(appsContext.state.videoPlayer.assetObj.id)){
            cart.push(appsContext.state.videoPlayer.assetObj.id)
            // e.target.style.color = 'red'
            // document.getElementById("fav_"+appsContext.state.videoPlayer.assetObj.id).style.color = "red";
            // document.getElementById("fav_"+assetId).style.backgroundColor = "lightblue";
            Cache.setItem('shotwire-shopCart', cart, { expires: expiration.getTime() })
            appsContext.dispatch({type:"SHOPCART", shopCart:cart})           
            // console.log('added to shopCart.')
            // console.log('cart.', cart)
          }else{
            // e.target.style.color = 'white'
            // document.getElementById("fav_"+appsContext.state.videoPlayer.assetObj.id).style.color = "white";
            // document.getElementById("fav_"+assetId).style.backgroundColor = "pink";
            const tmp = cart.filter(item => item !== appsContext.state.videoPlayer.assetObj.id)
            // console.log('tmp:', tmp)
            Cache.setItem('shotwire-shopCart', tmp, { expires: expiration.getTime() })
            cart = Cache.getItem('shotwire-shopCart')
            appsContext.dispatch({type:"SHOPCART", shopCart:cart})
            // console.log('removed from shopCart.')
            // console.log('cart.:', cart)
         }
       }
          // navigate(
          //     "/",
          //     // {
          //     //   state: { searchform },
          //     // }
          //   )
      }else if(arg === 'showEmbedModal'){
        // event.preventDefault();
        // console.log('props.location.pathname:', props.location.pathname)
          // console.log('clicked menu link:', event.target)
          // currentCategory = category
          // appsContext.dispatch({ type: "NEWCATEGORY",  currentCategory});
          // console.log('embedModalShow.', appsContext.state.embedModalShow)
          Analytics.record({
                  name: 'EMBED', 
                  // Attribute values must be strings
                  attributes: { 
                    // username: userInfo.username,
                    // userSub: userInfo.attributes[`sub`],
                    id: appsContext.state.videoPlayer.assetObj.id,
                    video: appsContext.state.videoPlayer.assetObj.name,  
                    // highRezFileName: appsContext.state.videoPlayer.assetObj.highRezFileName, 
                    // setId: appsContext.state.videoPlayer.assetObj.realSetId,
                    // setDate: appsContext.state.videoPlayer.assetObj.setDate,
                    // signedUrl: appsContext.state.videoPlayer.assetObj,
                    // thumbNailUrl: appsContext.state.videoPlayer.assetObj.thumbNailUrl
                  }
                });
          appsContext.dispatch({type:"SHOWEMBEDMODAL", embedModalShow:true})
      }else if(arg === 'showDownloadsModal'){
        // event.preventDefault();
        // console.log('props.location.pathname:', props.location.pathname)
          // console.log('clicked menu link:', event.target)
          // currentCategory = category
          // appsContext.dispatch({ type: "NEWCATEGORY",  currentCategory});
          // console.log('downloadsModalShow.', appsContext.state.downloadsModalShow)
          Analytics.record({
                  name: 'DOWNLOAD', 
                  // Attribute values must be strings
                  attributes: { 
                    // username: userInfo.username,
                    // userSub: userInfo.attributes[`sub`],
                    id: appsContext.state.videoPlayer.assetObj.id,
                    video: appsContext.state.videoPlayer.assetObj.name,  
                    // highRezFileName: appsContext.state.videoPlayer.assetObj.highRezFileName, 
                    // setId: appsContext.state.videoPlayer.assetObj.realSetId,
                    // setDate: appsContext.state.videoPlayer.assetObj.setDate,
                    // signedUrl: appsContext.state.videoPlayer.assetObj,
                    // thumbNailUrl: appsContext.state.videoPlayer.assetObj.thumbNailUrl
                  }
                });
          appsContext.dispatch({type:"SHOWDOWNLOADSMODAL", downloadsModalShow:true})
      }else if(arg === 'buySubscription'){
        // event.preventDefault();
        // console.log('props.location.pathname:', props.location.pathname)
          // console.log('clicked menu link:', event.target)
          // currentCategory = category
          // appsContext.dispatch({ type: "NEWCATEGORY",  currentCategory});
          // console.log('buysub.', appsContext.state.buySubsciptionModalShow)
          appsContext.dispatch({type:"SHOWBUYSUBMODAL", buySubsciptionModalShow:true})
      }else if(arg === 'videoProblem'){
        // event.preventDefault();
        // console.log('props.location.pathname:', props.location.pathname)
          // console.log('clicked menu link:', event.target)
          // currentCategory = category
          // appsContext.dispatch({ type: "NEWCATEGORY",  currentCategory});
      }else if(arg === 'addFavorite'){
        let favs = Cache.getItem('shotwire-favorites')

        // console.log('favs:', favs)
        const expiration = new Date(2020, 12, 18)
        
        if(favs == null){
          // console.log('set item...')
          Cache.setItem('shotwire-favorites', [ appsContext.state.videoPlayer.assetObj.id ], { expires: expiration.getTime() });
          appsContext.dispatch({type:"FAVORITES", favorites:[ appsContext.state.videoPlayer.assetObj.id ]})
          // document.getElementById("fav_"+appsContext.state.videoPlayer.assetObj.assetId).style.color = "red";
        }else{
          
          if(favs.includes(appsContext.state.videoPlayer.assetObj.id)===false){
            favs.push(appsContext.state.videoPlayer.assetObj.id)
            // e.target.style.color = 'red'
            // document.getElementById("fav_"+appsContext.state.videoPlayer.assetObj.id).style.color = "red";
            // document.getElementById("fav_"+assetId).style.backgroundColor = "lightblue";
            Cache.setItem('shotwire-favorites', favs, { expires: expiration.getTime() })
            appsContext.dispatch({type:"FAVORITES", favorites:favs})           
            // console.log('added to favs.')
            // console.log('favs.', favs)
          }else{
            // e.target.style.color = 'white'
            // document.getElementById("fav_"+appsContext.state.videoPlayer.assetObj.id).style.color = "white";
            // document.getElementById("fav_"+assetId).style.backgroundColor = "pink";
            const tmp = favs.filter(item => item !== appsContext.state.videoPlayer.assetObj.id)
            // console.log('tmp:', tmp)
            Cache.setItem('shotwire-favorites', tmp, { expires: expiration.getTime() })
            favs = Cache.getItem('shotwire-favorites')
            appsContext.dispatch({type:"FAVORITES", favorites:favs})
            // console.log('removed from favs.')
            // console.log('favs.:', favs)
         }
       }
       // console.log('appsContext.state.favorites:', appsContext.state.favorites)
       // console.log('favs:.', favs)
        
      }else if(arg === 'metaSubmit'){
        // console.log('metaSubmit target:',e.target)
      }else if(arg === 'grabFrame'){
        // console.log('grabFrame')
        // const frame = captureVideoFrame(this.player.getInternalPlayer())
      }else if(arg === 'VideoSubscriptions'){
        // console.log('video subscriptions.')
         // currentCategory = category
          // console.log('currentCategory:', currentCategory)
          // appsContext.dispatch({ type: "NEWCATEGORY",  currentCategory});
          // appsContext.dispatch({ type: "PAGETOKENS", nextToken: null })
        
          navigate(
              '/VideoSubscriptions',
              // {
              //   state: { searchform },
              // }
            )

          Analytics.record({
                  name: 'VIDEOSUBSCRIPTIONVIEW', 
                  // Attribute values must be strings
                  attributes: { 
                    id: appsContext.state.videoPlayer.assetObj.id,
                    video: appsContext.state.videoPlayer.assetObj.name,  
                    // highRezFileName: appsContext.state.videoPlayer.assetObj.highRezFileName, 
                    // setId: appsContext.state.videoPlayer.assetObj.realSetId,
                    // setDate: appsContext.state.videoPlayer.assetObj.setDate,
                    // signedUrl: appsContext.state.videoPlayer.assetObj,
                    // thumbNailUrl: appsContext.state.videoPlayer.assetObj.thumbNailUrl
                  }
                });
          

      }else if(arg === 'updateSrcMediaInfo'){
          e.preventDefault()

          // console.log('check status key, fileName:', key , appsContext.state.proxyList[key].fileName)
          try{
              appsContext.dispatch({ type: "SRCMEDIAINFOUPDATE", isSrcMediaInfoUpdating: true })
              // console.log('appsContext.state.videoPlayer: ', appsContext.state.videoPlayer.assetObj.fileName)
              // return filename
              const OgSrcVideoJobInfo = await API.get('shotwireAPI', '/createVideoProxy', {
                              'queryStringParameters': {
                                // 'setId': appsContext.state.videoPlayer.assetObj.realSetId,
                                // 'setDate': appsContext.state.videoPlayer.assetObj.setDate,
                                // 'setName': appsContext.state.videoPlayer.assetObj.name,
                                // 'assetName': (dlType === 'hrdl') ? appsContext.state.videoPlayer.assetObj.highRezFileName : appsContext.state.videoPlayer.assetObj.highRezFileName.replace('.mp4','-h264.mp4'),
                                'jobAction': 'OgSrcVideoJobInfo',
                                // 's3srcUrl' : srcObj.srcUrl,
                                'ogSrcFile' : appsContext.state.videoPlayer.assetObj.fileName,
                                // 'jobTemplate': 'Shotwire-Custom-Job-User-'+ appsContext.state.user.username//'Shotwire-Watermarked-Proxies'                      
                              }
                            });
              // console.log('OgSrcVideoJobInfo:', JSON.stringify(OgSrcVideoJobInfo.OgSrcVideoJobInfo[0].srcMediainfo) )
              // console.log('OgSrcVideoJobInfo.OgSrcVideoJobInfo[0].srcMediainfo:', JSON.parse(OgSrcVideoJobInfo.OgSrcVideoJobInfo[0].srcMediainfo) )
              const mediainfo = JSON.parse(OgSrcVideoJobInfo.OgSrcVideoJobInfo[0].srcMediainfo)
              // console.log('mediainfo:', mediainfo )
              // return OgSrcVideoJobInfo.OgSrcVideoJobInfo
              if(OgSrcVideoJobInfo.OgSrcVideoJobInfo.length>0){                            
                  
                  const assetObj = {
                    id: appsContext.state.videoPlayer.assetObj.id,
                    srcMediainfo: OgSrcVideoJobInfo.OgSrcVideoJobInfo[0].srcMediainfo,
                    video_length: mediainfo.container.duration/1000,
                    fileSize: mediainfo.container.fileSize/1024/1024,
                    video_width: mediainfo.video[0].width,
                    video_height: mediainfo.video[0].height,
                    video_aspect: mediainfo.video[0].aspectRatio,
                    video_frames: mediainfo.video[0].frameCount,
                    // video_format: String,
                    video_bitrate: mediainfo.video[0].bitrate,
                    video_fps: mediainfo.video[0].framerate ,
                    audio_codec: mediainfo.audio[0].codec,
                    // audio_format: mediainfo.audio[0].format,
                    audio_bitrate: mediainfo.audio[0].bitrate,
                    audio_rate: mediainfo.audio[0].samplingRate,
                    audio_length: mediainfo.audio[0].duration,



                  }
                  // console.log('input assetObj:', assetObj)
                  if(typeof assetObj.srcMediainfo !== 'undefined'){
                      // console.log('updating asset, ...')
                     updateAssetDelta(assetObj)
                   }
              }

              // add video to delta table once proxy is created
              // appsContext.dispatch({type:"CREATEVIDEOPROXY", newAsset:{ name:srcObj.name, fileName: srcObj.name, archived: 0, assetType: 3, s3srcUrl: srcObj.srcUrl, proxyInfo: 'pending' } })
            // appsContext.addAssetDelta({ name:srcObj.name, fileName: srcObj.name, archived: 0, assetType: 3, s3srcUrl: srcObj.srcUrl, proxyInfo: 'pending' })
           
              
            }catch(err){
              //add an image placehold for assets with no image
              // dispatch({ type: "QUERY", albums: [{id:`none`,name:`none`}] });
              // console.log('media info error: ', err)
            }
      }
    }
  }

  const handleDownload = (e) => {
    e.preventDefault()
      // if (state === 'signedIn') {
          /* Do something when the user has signed-in */
          try{
            // getVideoUrl('hrdl')
            // vPlayer.isDownloading = true
            // appsContext.dispatch({ type: "VPLAYER", vPlayer });

            //Record an event
            // Analytics.record('some-event-name');

            //Record a custom event
            // Analytics.record({
            //     name: 'Video-Download',
            //     attributes: { file: appsContext.state.videoPlayer.assetObj.highRezFileName, name: appsContext.state.videoPlayer.assetObj.name }
            // });
          
            // appsContext.dispatch({ type: "SRC", signedUrl: signedUrl.videoUrl });
// console.log('caching download stats...')
            Analytics.record({
                  name: 'DOWNLOAD', 
                  // Attribute values must be strings
                  attributes: { 
                    username: userInfo.username,
                    userSub: userInfo.attributes[`sub`],
                    id: appsContext.state.videoPlayer.assetObj.id,
                    video: appsContext.state.videoPlayer.assetObj.name,  
                    // highRezFileName: appsContext.state.videoPlayer.assetObj.highRezFileName, 
                    // setId: appsContext.state.videoPlayer.assetObj.realSetId,
                    // setDate: appsContext.state.videoPlayer.assetObj.setDate,
                    // signedUrl: appsContext.state.videoPlayer.assetObj,
                    // thumbNailUrl: appsContext.state.videoPlayer.assetObj.thumbNailUrl
                  }
                });
  
            if(myDownloads == null){
              // console.log('set item...')
              Cache.setItem('shotwire-myDownloads', [ appsContext.state.videoPlayer.assetObj.id ], { expires: expiration.getTime() });
              appsContext.dispatch({type:"MYDOWNLOADS", myDownloads:[ appsContext.state.videoPlayer.assetObj.id ]})
            }else{
              
              if(!myDownloads.includes(appsContext.state.videoPlayer.assetObj.id)){
                myDownloads.push(appsContext.state.videoPlayer.assetObj.id)
                // e.target.style.color = 'red'
                // document.getElementById("fav_"+assetId).style.color = "red";
                // document.getElementById("fav_"+assetId).style.backgroundColor = "lightblue";
                Cache.setItem('shotwire-myDownloads', myDownloads, { expires: expiration.getTime() })
                appsContext.dispatch({type:"MYDOWNLOADS", myDownloads:myDownloads})           
                // console.log('added to favs.')
                // console.log('favs.', favs)
              }else{
                // e.target.style.color = 'white'
                // document.getElementById("fav_"+assetId).style.color = "white";
                // document.getElementById("fav_"+assetId).style.backgroundColor = "pink";
                const tmp = myDownloads.filter(item => item !== appsContext.state.videoPlayer.assetObj.id)
                // console.log('tmp:', tmp)
                Cache.setItem('shotwire-myDownloads', tmp, { expires: expiration.getTime() })
                myDownloads = Cache.getItem('shotwire-myDownloads')
                appsContext.dispatch({type:"MYDOWNLOADS", myDownloads:myDownloads})
                // console.log('removed from favs.')
                // console.log('favs.:', favs)
             }
              
            }
            //*/

          }catch(err){
            
            // console.log('error: ', err)
          }

      // }else if(state === 'signIn'){

      // }
  }


  const handlePlayerRef = () => {
    // e.preventDefault()
    console.log('handlePlayerRef')
    // console.log('video node:',playerDiv.current.querySelector('video'))
        // console.log('video node:',playerDiv.current)

    // setState({ playing: true })
    // vPlayer.playing = true
    // appsContext.dispatch({ type: "VPLAYER",  vPlayer });
  }




  async function photoSetObject(id){


        const setObj = await API.graphql(
            {
                // query: getShotwireAssetsDelta,
                query: getShotwireAlbum,
                variables: { id: id },
                authMode:  (appsContext.state.isAdmin===true || appsContext.state.isUploader===true || appsContext.state.user!==null)  ? 'AMAZON_COGNITO_USER_POOLS': 'AWS_IAM'
              }
            );
        console.log('setObj>', setObj.data.getShotwireAlbum)

        setObj.data.getShotwireAlbum.photos = (setObj.data.getShotwireAlbum.photos !== null)? setObj.data.getShotwireAlbum.photos:[]
        appsContext.dispatch({ type: "PHOTOALBUM", photoAlbum: setObj.data.getShotwireAlbum });
        
        if(setObj.data.getShotwireAlbum.photos===null||setObj.data.getShotwireAlbum.photos.length===0){
          console.log('checking for album for photos->')
          await getAlbumPhotos(setObj.data.getShotwireAlbum)
        }
       
        // tmp.setObj = setObj.data.getShotwireAlbum
        console.log('dispatch photo album update')
        

  }

  async function assetDeltaObject(id){
    // console.log('get asset id:', id)
    // console.log('user:', appsContext.state.user)
    // console.log('appsContext.state.isAdmin:', appsContext.state.isAdmin)
    // console.log('appsContext.state.isUploader:', appsContext.state.isUploader)
    try{

        const assetObj = await API.graphql(
            {
                // query: getShotwireAssetsDelta,
                query: getShotwireAssetsDeltaPublic,
                variables: { id: id },
                authMode:  (appsContext.state.isAdmin===true || appsContext.state.isUploader===true || appsContext.state.user!==null)  ? 'AMAZON_COGNITO_USER_POOLS': 'AWS_IAM'
              }
            );
      

        // console.log('assetObj::', assetObj.data.getShotwireAssetsDelta)
      //               console.log('vPlayer:',vPlayer)
        const tmp = vPlayer
        //update vplayer
        // vPlayer.signedUrl=''
        // tmp.image = assetObj.data.getShotwireAssetObject.thumbNailUrl
        tmp.assetObj = assetObj.data.getShotwireAssetsDelta
        // vPlayer.playing=false
        // vPlayer.isDownloading=0
        // vPlayer.hrDlSrc= ''
        appsContext.dispatch({ type: "VPLAYER", vPlayer: tmp });

    }catch(err){
      // console.log('assetDeltaObject err', err)
    }
    
  }

  async function shotwireAssetObject(params){

    try{
    const assetObj = await API.graphql(graphqlOperation(getLegacyShotwireAsset, {realSetId: params.arsid, name:params.name } ))
    // console.log('assetObj::', assetObj)
    // console.log('assetObj=>>', assetObj.data.getLegacyShotwireAsset.items)
    //               console.log('vPlayer:',vPlayer)
                  const tmp = vPlayer
                  //update vplayer
                  // vPlayer.signedUrl=''
                  // tmp.image = assetObj.data.getShotwireAssetObject.thumbNailUrl
                  tmp.assetObj = assetObj.data.getLegacyShotwireAsset.items[0]
                  // vPlayer.playing=false
                  // vPlayer.isDownloading=0
                  // vPlayer.hrDlSrc= ''
                  appsContext.dispatch({ type: "VPLAYER", vPlayer: tmp });
                }catch(err){
                  // console.log('err:',err)
                }
  }


 


  async function updateAssetDelta(assetObj) {
    try{
      // console.log('date:', assetObj.setDate)
      if(typeof assetObj.setDate !== 'undefined'){
            const formattedDate = [assetObj.setDate.slice(0, 4), assetObj.setDate.slice(4,6), assetObj.setDate.slice(6,8)].join('-')
            // console.log('formattedDate:', formattedDate)
            const eventDate = new Date(formattedDate).getTime()
            // console.log('eventDate:',eventDate)
          }
      // const asset = {...assetObj, archived: 1, category: '', timestamp: eventDate } 
      const asset = {...assetObj }      
     
      Object.keys(asset).forEach((key) => (asset[key] == null||asset[key] == '') && delete asset[key]);
      // console.log('asset->', asset)
      if(asset.category=='SELECT A CATEGORY' && asset.archived==1){
        // console.log('abort, category required to archive.')
        appsContext.dispatch({ type:"TOASTS", toasts:{ show:true, display:'block', displayTopPosition: '1500px', title:"Error", body: 'A CATEGORY Must be selected to Publish a video.' }})
      }else{

        if(asset.archived==0 || asset.archived=="0" || asset.archived==="false" || asset.archived===false){
          asset.category = "Ingest"
        }
        // console.log('asset=>', asset)
        const savemeta = await API.graphql(graphqlOperation(assetDeltaUpdate, { input: asset }));
        // console.log('savemeta:', savemeta)
        appsContext.dispatch({ type: "METAUPDATE", isMetaSaving: false })
        appsContext.dispatch({ type: "SRCMEDIAINFOUPDATE", isSrcMediaInfoUpdating: false })

      }
      
    } catch(err) {
      appsContext.dispatch({ type: "METAUPDATE", isMetaSaving: false })
      appsContext.dispatch({ type: "SRCMEDIAINFOUPDATE", isSrcMediaInfoUpdating: false })
      // console.log('delta err:',err)
    }    
  }

  function DownloadsModal(props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Shotwire Video Download Options:
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4>Your Account Options:</h4>
          <p>
            Download Options Here
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
    );
  }

  function EmbedModal(props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Shotwire Video Embed
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4>Embed Options:</h4>
          <p>
            Embed Code here
            
            Thumbnail URL: {appsContext.state.videoPlayer.assetObj.thumbNailUrl}
            
            {<iframe src="https://video2020.shotwire.com/VideoPlayerB/?aid={appsContext.state.videoPlayer.assetObj.id}" width="640" height="360" frameBorder="none" scrolling="no" marginWidth="0"></iframe> 
              }          
            </p>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
    );
  }

useEffect(() => {
      // console.log('get for user product and stats...')
      let isMounted = true
      if(isMounted === true){
        // console.log('setting up user...')

          if(appsContext.state.user){
            const  userInfo  = appsContext.state.user
            // console.log('userInfo:->', userInfo)

            if(typeof userInfo.signInUserSession.idToken.payload[`cognito:groups`] != 'undefined'){
              uGroups = userInfo.signInUserSession.idToken.payload[`cognito:groups`]
              // console.log('uGroups:',uGroups)
              // console.log('uRoles: ', uRoles)
              if( uGroups.some(r => ['Admins'].indexOf(r) >= 0) ){
                // console.log('Admin user.')
                 appsContext.dispatch({ type: "ISADMIN", isAdmin:true }) 
               }
              if( uGroups.some(r => ['Uploaders'].indexOf(r) >= 0) ){
                // console.log('Uploader user.')
                 appsContext.dispatch({ type: "ISUPLOADER", isUploader:true }) 
               }
              // console.log('isAdmin:', isAdmin)
            }

            if(typeof userInfo.signInUserSession.idToken.payload[`custom:identityId`] != 'undefined'){
              uIdentityId = userInfo.signInUserSession.idToken.payload[`custom:identityId`]
              // console.log('custom Identity:',uIdentityId)
              appsContext.dispatch({ type: "AWSUSERIDS3", uIdentityIdS3:uIdentityId }) 
              
            }

            // const CUSTOM_IDENTITY_FIELD = "custom:identityId";

            // if (attributes && !attributes[CUSTOM_IDENTITY_FIELD]) {
            //     await Auth.updateUserAttributes(currUser, {
            //       [CUSTOM_IDENTITY_FIELD]: (await Auth.currentUserCredentials())
            //         .identityId,
            //     });
            //     onInfo("Welcome to Tippify, " + currUser.username);
            // }

         
          }
        }

        return () => {
                  //console.log('state2: ', state)
                  // subscription.unsubscribe();
                  isMounted = false
                  
                };

  },[ ])

  useEffect(() => {
          let isMounted = true
            
          if(isMounted === true){
            // console.log('mounted...')
             setupElements();
             cart = Cache.getItem('shotwire-shopCart')
             appsContext.dispatch({type:"SHOPCART", shopCart:cart}) 
             favs = Cache.getItem('shotwire-favorites')
             appsContext.dispatch({type:"FAVORITES", favorites:favs})
             myDownloads = Cache.getItem('myDownloads')
           
               
              // if(appsContext.state.user){
              //   userInfo  = appsContext.state.user
              //     // console.log('userInfo: ', userInfo)

              //     if(typeof userInfo.signInUserSession.idToken.payload[`cognito:groups`] != 'undefined'){
              //       uGroups = userInfo.signInUserSession.idToken.payload[`cognito:groups`]
              //       // console.log('uGroups:',uGroups)
              //       // console.log('uRoles: ', uRoles)
              //       if( uGroups.some(r => ['Admins'].indexOf(r) >= 0) ){
              //         // console.log('Admin user.')
              //          appsContext.dispatch({ type: "ISADMIN", isAdmin:true }) 
              //        }
              //       if( uGroups.some(r => ['Uploaders'].indexOf(r) >= 0) ){
              //         // console.log('Uploader user.')
              //          appsContext.dispatch({ type: "ISUPLOADER", isUploader:true }) 
              //        }
              //       // console.log('isAdmin:', isAdmin)
              //     }                      
              // }
              // console.log('userInfo:', userInfo)
              // console.log('video asset:', appsContext.state.videoPlayer)

              // if(typeof appsContext.state.photoAlbum.id == 'undefined'){
                try{
                  // console.log('location.search:', location.search)
                  // console.log('location.search.arsid:', location.search.arsid)
                  searchParams = new URLSearchParams(location.search)
                  // console.log('searchParams:', searchParams)
                  // console.log('location.search.aid:', searchParams.get("aid") )
                  // console.log('location.search.arsid:', searchParams.get("arsid") )
                  if(searchParams.get("psid")){
                    console.log('psid:', searchParams.get("psid") )
                    
                    // assetDeltaObject(searchParams.get("aid"))
                    photoSetObject(searchParams.get("psid"))
                    

                  }
                  if(searchParams.get("arsid")){
                    // console.log('arsid:', searchParams.get("arsid") )
                    // console.log('location.search.an:', searchParams.get("an") )
                    shotwireAssetObject({arsid: (searchParams.get("arsid")), name:(searchParams.get("an")) })
                  }
                }catch(err){ 
                  // console.log('asset err:', err) 
                }
              
              // }
            }
               // console.log('video player:', appsContext.state.videoPlayer)
              // const metaForm = {}
              // console.log('appsContext.state.videoPlayer.assetObj.setDate:',appsContext.state.videoPlayer.assetObj.setDate)
              // console.log('appsContext.state.metaForm.counter:', appsContext.state.metaForm.counter)
              if(typeof appsContext.state.metaForm.counter == 'undefined' || appsContext.state.metaForm.counter===0){
                // console.log('meta form init.')

                //populate metaform if empty
                if(appsContext.state.videoPlayer.assetObj!=null){

                  if(typeof appsContext.state.videoPlayer.assetObj.setDate !== 'undefined'){
                                    metaForm.yr = appsContext.state.videoPlayer.assetObj.setDate.slice(0, 4)
                                    metaForm.mo = appsContext.state.videoPlayer.assetObj.setDate.slice(4,6) 
                                    metaForm.day = appsContext.state.videoPlayer.assetObj.setDate.slice(6,8)
                                    // console.log('yyyy, mo, dd => ', yr ,  mo , day)
                                    }
                        metaForm.category = appsContext.state.videoPlayer.assetObj.category
                        metaForm.archived = appsContext.state.videoPlayer.assetObj.archived

                        Object.keys(appsContext.state.videoPlayer.assetObj).map((keyName, i) => {
                          if(MetaEditable.includes(keyName)){
                            metaForm[keyName] = appsContext.state.videoPlayer.assetObj[keyName]
                          }
                        })
                        metaForm.marketplace = (appsContext.state.videoPlayer.assetObj.marketplace) ? appsContext.state.videoPlayer.assetObj.marketplace : false
                if(typeof appsContext.state.videoPlayer.assetObj !== 'undefined'){
                
                  if(typeof appsContext.state.videoPlayer.assetObj.srcMediainfo !== 'undefined') srcMediainfo = JSON.parse(appsContext.state.videoPlayer.assetObj.srcMediainfo)
                  else{
                    //get media info by default if exists and update.

                   srcMediainfo = null
                  }
                }
                
                // console.log('srcMediainfo:', srcMediainfo)
                // fileSize = appsContext.state.videoPlayer.assetObj.fileSize
                // // console.log('fileSize.',fileSize)
                // if( !(fileSize>0) ){
                //    if(srcMediainfo !== null) fileSize = Math.round(srcMediainfo.container.fileSize/1048576)
                // }
                // console.log('srcMediainfo->', srcMediainfo)
                // console.log('fileSize:',fileSize)
                // appsContext.dispatch({ type:'VPLAYER',  vPlayer:{...appsContext.state.videoPlayer,fileSize:fileSize} } )

                appsContext.dispatch({type:'METAFORM', metaForm})

                // Analytics.record({
                //   name: 'VIDEOPLAYER', 
                //   // Attribute values must be strings
                //   attributes: { 
                //     id: appsContext.state.videoPlayer.assetObj.id,
                //     video: appsContext.state.videoPlayer.assetObj.name,  
                //     // highRezFileName: appsContext.state.videoPlayer.assetObj.highRezFileName, 
                //     // setId: appsContext.state.videoPlayer.assetObj.realSetId,
                //     // setDate: appsContext.state.videoPlayer.assetObj.setDate,
                //     // signedUrl: appsContext.state.videoPlayer.assetObj,
                //     // thumbNailUrl: appsContext.state.videoPlayer.assetObj.thumbNailUrl
                //   }
                // });


                }else{
                  metaForm.yr = nowYr
                  metaForm.mo = nowMo
                  metaForm.day = nowDay
                  
                }

               

                metaForm.counter = (appsContext.state.metaForm.counter) ? appsContext.state.metaForm.counter++ : 0
                
                // console.log('appsContext.state.videoPlayer.assetObj:',appsContext.state.videoPlayer.assetObj)
                
                
              

              }else{
                // console.log('updating meta form.')
                appsContext.dispatch({type:'METAFORM', metaForm})
              }
              
              

              

              // if(typeof appsContext.state !== 'undefined'){
                // console.log('appsContext.state.videoPlayer.assetObj.dashUrl:', appsContext.state.videoPlayer.assetObj.dashUrl)
                // console.log('appsContext.state.videoPlayer.assetObj.hlsUrl:',appsContext.state.videoPlayer.assetObj.hlsUrl)
                // console.log('appsContext.state.videoPlayer.assetObj.mp4Urls:',appsContext.state.videoPlayer.assetObj.mp4Urls)
                
                // if(appsContext.state.videoPlayer.assetObj.dashUrl == null
                //     && appsContext.state.videoPlayer.assetObj.hlsUrl == null
                //     && appsContext.state.videoPlayer.assetObj.mp4Urls == null){
                //   // console.log('getVideoUrl...')
                //   getVideoUrl()
                // } 
                // // console.log('calling getVideoUrl(hrdl)')
                // getVideoUrl('hrdl')
              // } 
             // console.log("assetObj: ",appsContext.state.videoPlayer.assetObj) 
             // console.log("videoPlayer: ",appsContext.state.videoPlayer.assetObj) 


              if(appsContext.state.isAdmin){
                // subscribe to asset meta updates to show changes in the video details? or just update from the meta form?
                // or just dispatch a call to get the asset data?
              }

              // console.log('photoAlbum:', appsContext.state.photoAlbum)

                return () => {
                  // console.log('video player unmounting...')
                  isMounted = false
                  appsContext.dispatch({type:'METAFORM', metaForm:{counter:0}})
                  // vPlayer.signedUrl=''
                  // vPlayer.image=''
                  // vPlayer.assetObj={}
                  // vPlayer.playing=false
                  // vPlayer.isDownloading=0
                  // vPlayer.hlDlSrc= ''
                  // appsContext.dispatch({ type: "VPLAYER", vPlayer });
               
                };
          }, [  appsContext.state.user,  appsContext.state.isAdmin, appsContext.state.isUploader ]);


useEffect(() => {
    let subscription,
        isMounted = true

    async function setupSubscription() {
      console.log('setting up create photo subscription:', appsContext.state.photoAlbum)

      const user = await Auth.currentAuthenticatedUser()
      const album = appsContext.state.photoAlbum
      // console.log('user=', user)
      const onCreateShotwirePhoto = /* GraphQL */ `
            subscription OnCreateShotwirePhoto {
              onCreateShotwirePhoto {
                id
                albumId
                owner
                members
                bucket
                
                labels
                iptc
                name
                album {
                  id
                  name
                  owner
                  members
                  photos {
                    nextToken
                  }
                }
              }
            }
          `;
          // fullsize {
          //         key
          //         width
          //         height
          //       }
          //       thumbnail {
          //         key
          //         width
          //         height
          //       }
          //       preview {
          //         key
          //         width
          //         height
          //       }
      subscription = API.graphql(graphqlOperation(onCreateShotwirePhoto,{ owner: user.username, })).subscribe({
        next: (data) => {
          console.log('new onCreateShotwirePhoto data:',data)
          // const newPhoto = data.value.data.onCreateShotwirePhoto
          // console.log('newPhoto=>', newPhoto)
          //onCreateShotwirePhoto
          const newAsset = data.value.data.onCreateShotwirePhoto//eventData.value.data.onCreateShotwireAssetsDelta;
                    console.log('new Photo Asset :',newAsset)
                    // console.log('appsContext.state.proxyList',appsContext.state.proxyList)
                    
                    if(newAsset !== null){

                        const replaceAsset = (element) => element.id === newAsset.id;
                        
                        let pList = album.photos
                        console.log('pList.', pList)
    
                        // pList.push(newAsset)
    
                        const replaceIndex = pList.findIndex(replaceAsset)
                        // console.log('replace index:' , replaceIndex)
                        // console.log('asset to replace: ', pList[replaceIndex] )
                       
                        // if(newAsset.uploader === appsContext.state.user.username){
                          if(replaceIndex !== -1) pList[replaceIndex] = newAsset//pList.push(newAsset)
                          else pList.push(newAsset)
                        // }
                        console.log('pre subcription photoAlbum:', appsContext.state.photoAlbum)
                        
                        album.photos = pList
                        console.log('album!', album)
                        // dispatch({ type: "SUBSCRIPTION", newAsset });
                        appsContext.dispatch({ type: "PHOTOSUBSCRIPTION", photoAlbum: album });
                    }
        }
      })
    }
    
            
    if(isMounted === true){
      setupSubscription()
    }

    return () => {
      isMounted = false
      subscription.unsubscribe();
      appsContext.dispatch({type:'CLEARSETVIEWALBUM', photoAlbum:{photos:[]}})
    }
  }, [])

// useEffect(() => {
//       // console.log('get for user product and stats...')
//       let isMounted = true
//       if(isMounted === true){
        
//           console.log('checking for album for photos...')
//           getAlbumPhotos(appsContext.state.photoAlbum.id)
        

         
          
//       }

//       return () => {
//           //console.log('state2: ', state)
//           // subscription.unsubscribe();
//           isMounted = false
          
//         };

//   },[ appsContext.state.photoAlbum.id ])

  async function getVideoUrl(dlType) {
    


      try{
        // console.log('appsContext.state.videoPlayer.assetObj:', appsContext.state.videoPlayer.assetObj)

        // const eventDate = new Date(formattedDate).getTime()
        
        // console.log('hrDlSrc: ',appsContext.state.videoPlayer.hrDlSrc)
        const signedUrl = ''
        let tmp = {}
        /* This is for legacy pcn videos only
        const signedUrl = await API.get('pcnvideoUrls', '/videos', {
                        'queryStringParameters': {
                          'setId': appsContext.state.videoPlayer.assetObj.realSetId,
                          'setDate': appsContext.state.videoPlayer.assetObj.setDate,
                          'setName': appsContext.state.videoPlayer.assetObj.name,
                          'assetName': (dlType === 'hrdl') ? appsContext.state.videoPlayer.assetObj.highRezFileName : appsContext.state.videoPlayer.assetObj.highRezFileName.replace('.mp4','-h264.mp4'),

                        }
                      });
                      //*/
      // const albumData = await API.graphql(graphqlOperation(ListAllAssets,{coId:"6B3E1A00BDAB94E4DA166A30A759D44C"}));
        // console.log('signedUrl:', signedUrl)

        // dispatch({ type: "SRC", signedUrl: signedUrl.videoUrl });
        // videoPlayer.signedUrl = signedUrl.videoUrl 
        //   image: location.state.image,
        //   assetObj: location.state.assetObj
        // }
        if(dlType === 'hrdl'){
              // console.log('GET HRDL...')
              // console.log('appsContext.state.videoPlayer.hrDlSrc-> ',appsContext.state.videoPlayer.hrDlSrc)
              // console.log('hrdl url:', signedUrl.videoUrl)
              // console.log('hr file:', assetObj.highRezFileName)

              // fetch(signedUrl.videoUrl)
              //     .then(response => {
              //       const filename =  location.state.assetObj.highRezFileName
              //       response.blob().then(blob => {
              //         let url = window.URL.createObjectURL(blob);
              //         let a = document.createElement('a');
              //         a.href = url;
              //         a.download = filename;
              //         a.click();
              //     });
              //  });

              // vPlayer.isDownloading = false
              if(appsContext.state.user){


                // console.log('appsContext.state.videoPlayer.assetObj.mp4Urls:',appsContext.state.videoPlayer.assetObj.mp4Urls[0])
                // console.log('signedUrl.videoUrl-:', signedUrl.videoUrl)
                // vPlayer.hrDlSrc = (appsContext.state.user) ? signedUrl.videoUrl : ''

                if((appsContext.state.videoPlayer.assetObj.mp4Urls!=null)){
                  
                  tmp.hrDlSrc = appsContext.state.videoPlayer.assetObj.mp4Urls[0].replace(/\{|\}/g,'').substr(2)  
/*
async getDataFetch(){
    const response =
      await fetch("https://dog.ceo/api/breeds/list/all",
        { headers: {'Content-Type': 'application/json'}}
      )
    console.log(await response.json())
}

                  var params = {
                      Bucket: "examplebucket", 
                      Key: "HappyFace.jpg"
                     };
                     s3.headObject(params, function(err, data) {
                       if (err) console.log(err, err.stack); // an error occurred
                       else     console.log(data);           // successful response
                       
                       data = {
                        AcceptRanges: "bytes", 
                        ContentLength: 3191, 
                        ContentType: "image/jpeg", 
                        ETag: "\"6805f2cfc46c0f04559748bb039d69ae\"", 
                        LastModified: <Date Representation>, 
                        Metadata: {
                        }, 
                        VersionId: "null"
                       }
                       
                     });             
   */               
                }else{

                  tmp.hrDlSrc = signedUrl.videoUrl 
                }


              }else tmp.hrDlSrc = ''
                // console.log('getVideoUrl dispatching...',appsContext.state.videoPlayer, tmp)
              appsContext.dispatch({ type: "VPLAYER", vPlayer:{...appsContext.state.videoPlayer,...tmp, fileSize:fileSize} })
        }
        else{
          
            // console.log('signedUrl:', signedUrl)

            // if((typeof appsContext.state.videoPlayer.assetObj.mp4Urls!='undefined')){
            //     //add array of src videos
            //     vPlayer.srcUrls={[
            //       {src: appsContext.state.videoPlayer.assetObj.hlsUrl, type: 'video/ogg'},
            //       {src: appsContext.state.videoPlayer.assetObj.mp4Urls[0], type: 'video/mp4'}
            //       {src: appsContext.state.videoPlayer.assetObj.dashUrl, type: 'video/mp4'},
                                  
            //     ]}
            // }
            

           appsContext.dispatch({ type: "SRC", signedUrl: signedUrl.videoUrl })
           // console.log('appsContext.state.signedUrl:', appsContext.state.signedUrl)
           // Record a custom event
            // Analytics.record({
            //     name: `Asset-Viewed`,
            //     attributes: { user: appsContext.state.user.username, name: appsContext.state.videoPlayer.assetObj.name }
            // })
       }
        
      }catch(err){
        //add an image placehold for assets with no image
        // dispatch({ type: "QUERY", albums: [{id:`none`,name:`none`}] });
        console.log('error: ', err)
      }
      

  }

   async function createCheckoutSession(planId) { 
    let apiName = 'shotwireAPI'; // replace this with your api name.
    let path = '/create-checkout-session'; //replace this with the path you have configured on your API
    let myInit = {
                    body: {
                      planId: planId
                    }, // replace this with attributes you need
                    headers: {} // OPTIONAL e.g. "Content-Type": "application/json"
                 }

    await API.post(apiName, path, myInit).then(response => {
      // Add your code here
      // console.log('response:',response)
      return response.checkoutSessionId;
    }).then(sessId => {
        // Add your code here
        // console.log('sessId:',sessId)
        checkoutSessionId = sessId;

        stripePromise
          .redirectToCheckout({
            sessionId: sessId
          })
          .then(function(result) {
            // console.log('stripe result:', result)
            if(result.error){
              console.log('error:', result.error.message)
              appsContext.dispatch({ type:"TOASTS", toasts:{ show:true, display:'block', displayTopPosition: '1500px', title:"Purchase Error", body: result.error.message }})
            }
            // console.log("error");
            // If `redirectToCheckout` fails due to a browser or network
            // error, display the localized error message to your customer
            // using `result.error.message`.
          })
          .catch(function(err) {
            // console.log('stripe err:',err);
          });


    }).catch(error => {
        // console.log('sess error:',error)
    });
    // return sess
}

  async function captureVideoFrame(video, format, quality) {
        if (typeof video === 'string') {
            video = document.getElementById(video);
        }

        format = format || 'jpeg';
        quality = quality || 0.92;

        if (!video || (format !== 'png' && format !== 'jpeg')) {
            return false;
        }

        var canvas = document.createElement("CANVAS");

        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;

        canvas.getContext('2d').drawImage(video, 0, 0);

        var dataUri = canvas.toDataURL('image/' + format, quality);
        var data = dataUri.split(',')[1];
        var mimeType = dataUri.split(';')[0].slice(5)

        var bytes = window.atob(data);
        var buf = new ArrayBuffer(bytes.length);
        var arr = new Uint8Array(buf);

        for (var i = 0; i < bytes.length; i++) {
            arr[i] = bytes.charCodeAt(i);
        }

        var blob = new Blob([ arr ], { type: mimeType });
        return { blob: blob, dataUri: dataUri, format: format };
    };

  const handleThumbnail = (e, action) => {
    e.preventDefault()
    // console.log('e.target', e.target)
    // console.log('key:', key)
    // console.log('action:', action)
    // console.log('asset:', appsContext.state.proxyList[key].thumbNailUrl)
    let count = 0, go = false

    if(action === 'save'){
      //update the assets new thumbnailurl to the db
      const assetObj = {
                            id: appsContext.state.videoPlayer.assetObj.id,
                            thumbNailUrl: appsContext.state.videoPlayer.assetObj.thumbNailUrl
                          }
      // console.log('update assetObj:', assetObj)
      updateAssetDelta(assetObj)

      
        // modifiedThumbPlyr = appsContext.state.modifiedThumbPlyr
        modifiedThumbPlyr = false
        appsContext.dispatch({ type: "MODIFIEDTHUMBPLAYR", modifiedThumbPlyr })
      
      

    }

    count = Math.round(appsContext.state.videoPlayer.assetObj.thumbNailUrl.substr(-7,7).replace(/(\.jpg)/,'')) 
    // console.log('count:' , count)
    if(action === 'minus'){
       
      if(count>0){
         count--
         go = true
      }
        
   }
    if(action === 'plus'){
       
      if(count<14){
        count++
        go = true
      }
      

    }

  
    if(go === true){
        let aObj = appsContext.state.videoPlayer.assetObj
        aObj.thumbNailUrl = appsContext.state.videoPlayer.assetObj.thumbNailUrl.replace(/(\d\d\d\.jpg)/, ("00" + count).slice(-3) + '.jpg' )
        // console.log('aObj:', aObj)
        // console.log('appsContext.state.videoPlayer:', appsContext.state.videoPlayer)
        // appsContext.dispatch({ type: "ARCHIVELIST", archiveListConnection: {items: aList, archiveListNextToken: null} });
        appsContext.dispatch({ type:'VPLAYER',  vPlayer:{...appsContext.state.videoPlayer, assetObj:aObj, image:aObj.thumbNailUrl} } )
        modifiedThumbPlyr = true
        appsContext.dispatch({ type: "MODIFIEDTHUMBPLAYR", modifiedThumbPlyr })
    }

// console.log('modifiedThumbPlyr:',modifiedThumbPlyr)
   
  }

  async function getVideoThumbnails(thumbNailUrl){
    try{
        
        // console.log('filename: ', filename)
        // return filename
        const videoThumbs = await API.get('shotwireAPI', '/createVideoProxy', {
                        'queryStringParameters': {
                          // 'setId': appsContext.state.videoPlayer.assetObj.realSetId,
                          // 'setDate': appsContext.state.videoPlayer.assetObj.setDate,
                          // 'setName': appsContext.state.videoPlayer.assetObj.name,
                          // 'assetName': (dlType === 'hrdl') ? appsContext.state.videoPlayer.assetObj.highRezFileName : appsContext.state.videoPlayer.assetObj.highRezFileName.replace('.mp4','-h264.mp4'),
                          'jobAction': 'getVideoThumbnails',
                          // 's3srcUrl' : srcObj.srcUrl,
                          'thumbNailUrl' : thumbNailUrl,
                          // 'jobTemplate': 'Shotwire-Custom-Job-User-'+ appsContext.state.user.username//'Shotwire-Watermarked-Proxies'                      
                        }
                      });
        // console.log('videoThumbs:', videoThumbs)
        return videoThumbs
        

        // add video to delta table once proxy is created
        // appsContext.dispatch({type:"CREATEVIDEOPROXY", newAsset:{ name:srcObj.name, fileName: srcObj.name, archived: 0, assetType: 3, s3srcUrl: srcObj.srcUrl, proxyInfo: 'pending' } })
      // appsContext.addAssetDelta({ name:srcObj.name, fileName: srcObj.name, archived: 0, assetType: 3, s3srcUrl: srcObj.srcUrl, proxyInfo: 'pending' })
     
        
      }catch(err){
        //add an image placehold for assets with no image
        // dispatch({ type: "QUERY", albums: [{id:`none`,name:`none`}] });
        console.log('error: ', err)
      }
  }

  // image={
  //                   (asset.thumbNailUrl!=null)?asset.thumbNailUrl:(
  //                                       (asset.assetId)?
  //                                       `https://d1w2buicv2uw3m.cloudfront.net/V2/6B3E1A00BDAB94E4DA166A30A759D44C/assets/${asset.assetId}.jpg`
  //                                       :`https://d1w2buicv2uw3m.cloudfront.net/V2/6B3E1A00BDAB94E4DA166A30A759D44C/assets/undefined.jpg`)

  // `https://s3.amazonaws.com/assets.shotloadr.com/9824537_17189PCNV_KevinHartCrashSite001.mp4`


function handlePhotoChange(event) {
  // this.setState({ file: event.target.files })
}

async function handlePhotoSubmit(e) {
  e.preventDefault()
  let res = await this.uploadFile(this.state.file);
}

const handlePhotoUploaderOnChange = async (e) => {
    // setUploading(true)
    console.log('e.target.files:', e.target.files)
    // appsContext.dispatch({'type':"PHOTOUPLOADER",isUploading:true})
    
    let files = [];
    for (var i=0; i<e.target.files.length; i++) {
      files.push(e.target.files.item(i));
    }
    // await Promise.all(files.map(f => uploadFile(f)));

    // setUploading(false)
    appsContext.dispatch({'type':"PHOTOUPLOADER",photoUploader:{ ...appsContext.state.photoUploader ,files:files}})

}

async function uploadPhotoFile(e){
  e.preventDefault()
  console.log('files to upload:', appsContext.state.photoUploader.files)

  // await Promise.all(files.map(f => uploadFile(f)));
  await Promise.all(appsContext.state.photoUploader.files.map( async f => {
        console.log('appsContext.state.photoAlbum:', appsContext.state.photoAlbum)
          console.log('file:',f)
          const fileKey = 'uploads/photos/'+appsContext.state.photoAlbum.id+'/'+f.name; // add or use uuid for filename or append to file names? need to filter name for bad chars? 
          const user = await Auth.currentUserCredentials();
          const uploadId = user.identityId
          console.log('uploadId:', uploadId)


          // const CUSTOM_IDENTITY_FIELD = "custom:identityId";

            // if (attributes && !attributes[CUSTOM_IDENTITY_FIELD]) {
            //     await Auth.updateUserAttributes(currUser, {
            //       [CUSTOM_IDENTITY_FIELD]: (await Auth.currentUserCredentials())
            //         .identityId,
            //     });
            //     onInfo("Welcome to Tippify, " + currUser.username);
            // }
          console.log('fileName:', fileKey)
          console.log('user:', user)
          console.log('appsContext.state.photoAlbum:', appsContext.state.photoAlbum)
          
          try{
            // await Storage.put(f.name, f, {
            //                                     level: 'private',
            //                                     contentType: f.type,
            //                                     progressCallback(progress) {
            //                                           console.log(`Uploaded: ${progress.loaded}/${progress.total}`);
                                                      
            //                                           photoUploader.uploadCompleted = progress.loaded/progress.total
            //                                           // appsContext.state.uploadCompleted = progress.loaded/progress.total
            //                                           appsContext.dispatch({ type: "PHOTOUPLOADER", photoUploader })
            //                                     }
            //                                   })
            //                                   .then (result => {
            //                                     console.log('result',result)
            //                                     photoUploader.isUploading = false
            //                                     photoUploader.uploadCompleted = 0
            //                                     photoUploader.uploadCounter++
            //                                     appsContext.dispatch({ type: "PHOTOUPLOADER", photoUploader })
            //                                     // processPreviewProxy(data.name)
            //                                   })
            //                                   .catch(err => {
            //                                     console.log('err',err)
            //                                     photoUploader.isUploading = false
            //                                     photoUploader.uploadCompleted = 0
            //                                     photoUploader.uploadCounter++
            //                                     appsContext.dispatch({ type: "PHOTOUPLOADER", photoUploader })
            //                                   });
            const result = 
            await Storage.vault.put(
              fileKey, 
              f, 
              {
                metadata: {
                  albumid: appsContext.state.photoAlbum.id,
                  owner: appsContext.state.photoAlbum.owner,
                  name: fileKey
                }
              }
            )
            console.log('Uploaded file: ', result);
            processPreviewProxy(fileKey,f.name,uploadId)
          }catch(e){
            console.log('upload error:', e)
          }

      }

    ));

  
//   const files = event.target.files;

// for (let i = 0; i < files.length; i++) {
//     formData.append(`images[${i}]`, files[i])
// }
  // const formData = new FormData();

  // formData.append('image', File)
  // axios.post(UPLOAD_ENDPOINT, formData, {
  //   headers: {
  //     'content-type': 'multipart/form-data'
  //   }
  // }).then(response => {
  //   console.log(response.data)
  // });
}

  const processPreviewProxy = (fileKey,name,identityId) => {
    console.log('fileKey:',fileKey)
    console.log('name:',name)
    console.log('identityId:',identityId)
    const newId = uuid()

    const privateFileKey = identityId +'/'+fileKey
    console.log('privateFileKey:',privateFileKey)

    // console.log('process fileKey:', fileKey)
    // console.log('user:', appsContext.state.user)
    // id
    //   albumId
    //   owner
    //   members
    //   bucket
    //   fullsize {
    //     key
    //     width
    //     height
    //   }
    //   thumbnail {
    //     key
    //     width
    //     height
    //   }
    //   preview {
    //     key
    //     width
    //     height
    //   }
    //   labels
    //   iptc
    // name
    //   album {
    //     id
    //     name
    //     owner
    //     members
    //     photos {
    //       nextToken
    //     }
    //   }


// id
//         albumId
//         owner
//         bucket
        
//         album {
//           id
//           name
//           owner
//         }
 

    const asset = { id: newId, 
                    albumId: appsContext.state.photoAlbum.id,
                    name: name,
                    fullsize: {
                        key: privateFileKey,
                        width: 2000,
                        height: 2000
                      } ,
                      thumbnail: {
                      key: 'protected/'+appsContext.state.uIdentityIdS3+'/thumbs/'+appsContext.state.photoAlbum.id+'/'+name,
                      width: 200,
                      height: 200
                    },
                    preview: {
                      key: 'protected/'+appsContext.state.uIdentityIdS3+'/proxies/'+appsContext.state.photoAlbum.id+'/'+name,
                      width: 600,
                      height: 600
                    },
                    owner: appsContext.state.user.username,
                    // members
                    bucket: 'shotwirecomfc53e21aa63e40c7b4f60c37fdf92c0e01858-master',
                      // archived: 0, 
                      // assetType: 3, 
                    // category:'Ingest', 
                    // workflowStatus: 'Ingest', 
                    // uploader: appsContext.state.user.username
                    
                    }
    console.log('process asset:', asset)
      

    Storage.get(fileKey, {
      level: 'private',
      // download: true, //get body , not just signed url , was gonna use this for copying to protected proxy directory
      // expires: 300
    })
    .then(result => {
      console.log('download Link:',result)
      // addAssetDelta( asset ) 
      addPhotoAsset( asset )
      // createVideoProxy({srcUrl: result, name: newId + '_' + fileKey})
      // Storage.put( newId + '_' + appsContext.state.fileList[fileListIndex].key, result.Body, {
      //   level: 'protected'
      //   })
     
    })
    // .then(result =>{
    //   // console.log('createVideoProxy result:', result)
    //   const asset = { id: newId, name: fileKey, fileName: newId+'_'+fileKey, archived: 0, assetType: 3, category:'Ingest', workflowStatus: 'Ingest', uploader: appsContext.state.user.username }
    //   console.log('asset:', asset)
    //   addAssetDelta( asset ) 
    //   //add ingest to proxy list
    //   // appsContext.dispatch({ type: "PROXYSTATS", isProxyProcessing: false })
      
    // })
    .catch(err =>{
      
      console.log('proxy preview err:',err)

    });
  }

  const createAssetDelta = `mutation createAssetDelta($input: CreateShotwireAssetsDeltaInput!) {
      createShotwireAssetsDelta(input: $input) {
        id
        assetId
        name
        uploader
        fileName
        workflowStatus
        category
        assetType
        archived


      }
    }
    `;

    async function addPhotoAsset(photoObj) {
      // const asset = {...assetObj, archived: 1}
        // console.log('props:',props)
        // console.log('albumid:',albumid)
        // const photo = { 
        //   id: id,
        //   // owner: props.album.owner,
        //   photoAlbumId: albumid,
        //   // bucket: bucketName,
        //   // thumbnail: sizes.thumbnail,
        //   // fullsize: sizes.fullsize,
        //   // createdAt: new Date().getTime()
        // };
  //        const createPhoto = gql`
  //   mutation CreatePhoto(
  //     $input: CreatePhotoInput!
  //     $condition: ModelPhotoConditionInput
  //   ) {
  //     createPhoto(input: $input, condition: $condition) {
  //       id
  //       albumId
  //       owner
  //       bucket
  //       fullsize {
  //         key
  //         width
  //         height
  //       }
  //       thumbnail {
  //         key
  //         width
  //         height
  //       }
  //       album {
  //         id
  //         name
  //         owner
  //       }
  //       preview {
  //         key
  //         width
  //         height
  //       }
  //     }
  //   }
  // `;
  const createPhoto = `
    mutation CreateShotwirePhoto(
      $input: CreateShotwirePhotoInput!
      $condition: ModelShotwirePhotoConditionInput
    ) {
      createShotwirePhoto(input: $input, condition: $condition) {
        id
        albumId
        owner
        bucket
        
        album {
          id
          name
          owner
        }
       
      }
    }
  `;
      try{
        console.log('adding photoObj:', photoObj)
        // await API.graphql(graphqlOperation(createAssetDelta, { input: assetObj }));
        await API.graphql(graphqlOperation(createPhoto, { input: photoObj }));
          

      } catch (err){
        console.log('addasset err:',err)
      }
    
    }

    async function addAssetDelta(assetObj) {
      // const asset = {...assetObj, archived: 1}
        // console.log('props:',props)
        // console.log('albumid:',albumid)
        // const photo = { 
        //   id: id,
        //   // owner: props.album.owner,
        //   photoAlbumId: albumid,
        //   // bucket: bucketName,
        //   // thumbnail: sizes.thumbnail,
        //   // fullsize: sizes.fullsize,
        //   // createdAt: new Date().getTime()
        // };
      try{
        // console.log('added assetObj:', assetObj)
        // await API.graphql(graphqlOperation(createAssetDelta, { input: assetObj }));
        await API.graphql(graphqlOperation(createShotwireAssetsDelta, { input: assetObj }));
          

      } catch (err){
        // console.log('addasset err:',err)
      }
    
    }

     async function createVideoProxy(srcObj) {
    // console.log('image:', appsContext.state.videoPlayer.image)
    
      try{
        
        // console.log('hrDlSrc: ',appsContext.state.videoPlayer.hrDlSrc)
        const createProxy = await API.get('shotwireAPI', '/createVideoProxy', {
                        'queryStringParameters': {
                          // 'setId': appsContext.state.videoPlayer.assetObj.realSetId,
                          // 'setDate': appsContext.state.videoPlayer.assetObj.setDate,
                          // 'setName': appsContext.state.videoPlayer.assetObj.name,
                          // 'assetName': (dlType === 'hrdl') ? appsContext.state.videoPlayer.assetObj.highRezFileName : appsContext.state.videoPlayer.assetObj.highRezFileName.replace('.mp4','-h264.mp4'),
                          'jobAction': 'createProxy',
                          's3srcUrl' : srcObj.srcUrl,
                          'assetName' : srcObj.name,
                          'jobTemplate': 'Shotwire-Custom-Job-User-'+ appsContext.state.user.username,//'Shotwire-Watermarked-Proxies'  
                          'useSystemSrcFile': (srcObj.useSystemSrcFile === true ? true:false  ),
                          'isReprocessVideo': (srcObj.isReprocessVideo === true ? true:false  )         
                        }
                      });
        // console.log('createProxy:', createProxy)
        return createProxy
        // add video to delta table once proxy is created
        // appsContext.dispatch({type:"CREATEVIDEOPROXY", newAsset:{ name:srcObj.name, fileName: srcObj.name, archived: 0, assetType: 3, s3srcUrl: srcObj.srcUrl, proxyInfo: 'pending' } })
      // appsContext.addAssetDelta({ name:srcObj.name, fileName: srcObj.name, archived: 0, assetType: 3, s3srcUrl: srcObj.srcUrl, proxyInfo: 'pending' })
     
        
      }catch(err){
        //add an image placehold for assets with no image
        // dispatch({ type: "QUERY", albums: [{id:`none`,name:`none`}] });
        // console.log('error: ', err)
      }
      

  }

async function getAlbumPhotos(albumObj) {    
    console.log('getting photos for album:', albumObj.id)
      try{
        // console.log('currentCategory:', currentCategory)
      // const albumDataCached = await API.graphql(graphqlOperation(ListShotwireAssetsDeltas,{filter: {category:{contains: "red carpet"}}, limit: appsContext.state.cacheLimit, nextToken: appsContext.state.nextToken}));
        // console.log('appsContext.state.cacheLimit:', appsContext.state.cacheLimit)
        // const albumDataCached = await API.graphql(graphqlOperation(listAssetsByCategory,{ category: category, limit: appsContext.state.cacheLimit, nextToken: appsContext.state.nextToken}));
        // console.log('appsContext.state.user->', appsContext.state.user)
        const albumPhotos = await API.graphql(
          {
              // query: listAssetsByCategory,
              query: listPhotosByAlbum,
              variables: {  albumId: albumObj.id, 
                            // filter:{marketplace:{ne:false}},
                            // limit: appsContext.state.publicHomeCategoryLimit, 
                            // nextToken: appsContext.state.nextToken 
                          },
              authMode: ( (appsContext.state.isAdmin===true || appsContext.state.isUploader===true  || appsContext.state.user!==null )  ? 'AMAZON_COGNITO_USER_POOLS': 'AWS_IAM')
            }
          );
        
        console.log('state.photoAlbum:', appsContext.state.photoAlbum )
        console.log('albumObj:', albumObj)
        appsContext.dispatch({ type: "PHOTOALBUMQUERY", photoAlbum: {...albumObj, photos: albumPhotos.data.listPhotosByAlbum.items} });
        // appsContext.dispatch({ type: "CATEGORYCACHE", albumConnection: albumDataCached.data.listAssetsByCategory, category:category });
        
        
        // console.log('albumsByCategory:', appsContext.state.albumsByCategory)
      }catch(err){        
        //add an image placehold for assets with no image
        // appsContext.dispatch({ type: "QUERY", albums: [{id:`none`,name:`none`, headline:`none`}] });
        // console.log('get assets err: ', err)
      }      
  }

  //settting auth state to 'loading' hide the default signin form
if (appsContext.state) {
// console.log('appsContext.state.videoPlayer.assetObj: ',appsContext.state.videoPlayer.assetObj)
  if(appsContext.state.photoAlbum){
    // console.log('photo set:', appsContext.state.photoAlbum)
    // <title>Photo Set: - {(appsContext.state.photoAlbum.id===null)?'':appsContext.state.photoAlbum.name}</title>
     return(
          <Layout>
           <Helmet>
              <title>Photo Set</title>
              <link rel="icon" href={favicon} type="image/x-icon" />
            </Helmet>
          <Container>
          {
// <Row>
//  <Col sm={12} lg={12} className="d-md-none d-lg-none  d-xl-none" style={{textAlign:"center"}}>
 
//   </Col>
//   <Col sm={12} lg={12} className="d-none d-md-block d-lg-block d-xl-block">
//    <div sx={{
//     paddingTop:'15px',
//     width:'1024px',
//     height:'100px',
//     textAlign:'left'
//     // border:'1px solid white'
//     // paddingLeft: '10px',
//     // paddingTop: '20px'
//    }}>

//    </div>
//   </Col>
//  </Row>
}
 
 {
  // <iframe src="//rcm-na.amazon-adsystem.com/e/cm?o=1&p=48&l=ur1&category=primemusic&banner=1RY2Z0V0AAZV4RSZEXG2&f=ifr&linkID=58e39d79d4e3b1cbad1bc8f565011769&t=shotwire-20&tracking_id=shotwire-20" width="728" height="90" scrolling="no" border="0" marginWidth="0" style={{border:"none"}} frameBorder="0"></iframe>

  // <iframe 
  // src="//rcm-na.amazon-adsystem.com/e/cm?o=1&p=48&l=ur1&category=amazonhomepage&f=ifr&linkID=8355df7f0e378d27c9867426a3cc6c5a&t=shotwire-20&tracking_id=shotwire-20" width="728" height="90" scrolling="no" border="0" marginwidth="0" 
  // style={{border:"none;"}} frameborder="0"></iframe>
 // <iframe src="//rcm-na.amazon-adsystem.com/e/cm?o=1&p=48&l=ur1&category=primemain&banner=17T0MQR5WKGJCZNRHCG2&f=ifr&linkID=4fd690c31f8d7d5bd0c3ba76772d8945&t=shotwire-20&tracking_id=shotwire-20" width="728" height="90" scrolling="no" border="0" marginWidth="0" style={{border:"none"}} frameBorder="0"></iframe>
 // <iframe src="//rcm-na.amazon-adsystem.com/e/cm?o=1&p=48&l=ur1&category=primemain&banner=1FM3MKXZNJ0PPEBRV2R2&f=ifr&linkID=97f0c247004f7548309281f40eeb7940&t=shotwire-20&tracking_id=shotwire-20" width="728" height="90" scrolling="no" border="0" marginWidth="0" style={{border:"none"}} frameBorder="0"></iframe>

 }




          { 
          //   <Authenticator 
          //   errorMessage={ errMap }
          //   authState='loading'
          //   hideDefault={ true } 
          //   onStateChange={ handleAuthStateChange } 
          //   theme={ MyTheme }>                   
          //   <Greetings/>
          //   <AlwaysOn/>
          //   <SignIn/>
          //   <SignUp/>
          //   <ConfirmSignUp/>
          // </Authenticator>
        }
        <Row>
          <div sx={{
             
              // paddingTop:'15px',
  padding: '25px',
  // width:'1024px',
  // height:'100px',
  // border:'1px solid white'
  // paddingLeft: '30px',
          }}>
          <h6>PHOTO SET</h6>
          <h3>{appsContext.state.photoAlbum.name}</h3>
          <h7>{appsContext.state.photoAlbum.id}</h7>
          </div>
          </Row>
          <Row>
{
            // <AmplifyS3ImagePicker />
            // <S3ImageUpload />
            

            // <div>
            // {
            //               <Form.Button
            //                 onClick={() => document.getElementById('add-image-file-input').click()}
            //                 disabled={appsContext.state.photoUploader.isUploading}
            //                 icon='file image outline'
            //                 content={ appsContext.state.photoUploader.isUploading ? 'Uploading...' : 'Add Images' }
            //               />
            //             }
            //   <input
            //     id='add-image-file-input'
            //     type="file"
            //     accept='image/*'
            //     multiple
            //     onChange={handlePhotoUploaderOnChange}
            //     style={{ display: 'none' }}
            //   />
            // </div>

            /////////////////////////////////

            <form 
              style={{
                padding: '20px'
              }}
              onSubmit={e => uploadPhotoFile(e)}
              >
              <input 
                style={{
                  // backgroundColor: 'gray'
                }}
                type="file" 
                id="file" 
                multiple 
                name="file" 
                onChange={handlePhotoUploaderOnChange} />
                <br />
              <button type="submit" className="btn btn-info"> Upload Files </button>
            </form>


          }

          {
                      // <Picker 
                      //   title={ (appsContext.state.isUploading) ? (<span> Uploading <FontAwesomeIcon
                      //                         icon={faSpinner}
                      //                         size="sm"
                      //                         sx={{
                      //                           // marginRight: '0.5rem'
                      //                         }}
                      //                         spin
                      //                       /> </span>) :  
                      //               <span> Upload Photos </span>
                      //                        }
                      //   theme={
                      //       { photoPickerButton: {
                      //                                         'backgroundColor': '#2e4ea3', 
                      //                                         'border': 'none' , 
                      //                                         'padding': '10px',
                      //                                         // position: 'relative',
                      //                                          // width: '100%',
                      //                                          color: 'white',
                      //                                                             // height: 'auto'
                      //                                                             // objectFit: 'cover',
                      //                                                             'borderRadius': '3px',
                                                                                  
                      //                                                             '&:hover': {
                      //                                                               backgroundColor:'pink'
                      //                                                                // WebkitFilter: 'grayscale(5%);',
                      //                                                                // filter: 'grayscale(50%)',
                      //                                                             },
                      //                                                             display: (appsContext.state.isUploading ? 'none':'block')
                      //                                       }}
                      //     }
                      //   onPick={ data => {
                      //       // console.log(data)
                      //       uploader.isUploading = true

                      //       // const fext = data.name.match(/(\.mov)|(\.mp4)|(\.m4v)|(\.m4p)|(\.mpeg)|(\.mpg)|(\.avi)|(\.wmv)|(\.flv)/gi)
                      //       const fext = data.name.match(/(\.jpg)|(\.jpeg)/gi)
                      //       console.log('fext:', fext)
                            
                      //       // match "video" and reject if not a video
                      //       console.log('data.type:', data.type)
                      //       uploader.fileName = data.name


                      //       /////
                      //       console.log('file:',file)
                      //       const fileName = 'upload/'+uuid();
                      //       const user = await Auth.currentAuthenticatedUser();
                      //       console.log('fileName:', fileName)
                      //       console.log('user:', user)
                      //       try{
                      //         const result = await Storage.vault.put(
                      //           fileName, 
                      //           file, 
                      //           {
                      //             metadata: {
                      //               albumid: appsContext.state.photoAlbum.id,
                      //               owner: user.username,
                      //               name: file.name
                      //             }
                      //           }
                      //         );
                      //         console.log('Uploaded file: ', result);
                      //       }catch(e){
                      //         console.log('upload error:', e)
                      //       }
                      //       /////
                            
                      //       if(1
                      //         // data.type.match(/video/i)
                      //         ){
                                
                      //           // console.log('video type ok.')
                      //           Storage.put(data.name, data.file, {
                      //             level: 'private',
                      //             contentType: data.type,
                      //             progressCallback(progress) {
                      //                   console.log(`Uploaded: ${progress.loaded}/${progress.total}`);
                                        
                      //                   uploader.uploadCompleted = progress.loaded/progress.total
                      //                   // appsContext.state.uploadCompleted = progress.loaded/progress.total
                      //                   appsContext.dispatch({ type: "UPLOADER", uploader })
                      //             }
                      //           })
                      //           .then (result => {
                      //             // console.log(result)
                      //             uploader.isUploading = false
                      //             uploader.uploadCompleted = 0
                      //             uploader.uploadCounter++
                      //             appsContext.dispatch({ type: "UPLOADER", uploader })
                      //             processPreviewProxy(data.name)
                      //           })
                      //           .catch(err => {
                      //             // console.log(err)
                      //             uploader.isUploading = false
                      //             uploader.uploadCompleted = 0
                      //             uploader.uploadCounter++
                      //             appsContext.dispatch({ type: "UPLOADER", uploader })
                      //           });

                      //       }else{

                      //         // console.log('not a video!')
                      //         // appsContext.dispatch({ type:"TOASTS", toasts:{ show:true, display:'block', displayTopPosition:'-50px', title:"Error", body: "Upload Video Files Only!" }})
                      //         appsContext.dispatch({ type:"ERRORALERTS", errorAlerts: { ...appsContext.state.errorAlerts, UploadVideoErrorAlert:{ show:true, heading:"Error", body: "Upload Video Files Only!" } } })

                      //       }


                            
                      //     }


                      //   }
                      //   // onClick={e => handleUpload(e, 'videoUpload')}
                      //   track
                      //   >
                      // </Picker>

                    }

          </Row>
          <Row>
        
        { 

          (appsContext.state.photoAlbum.photos.length)?
          appsContext.state.photoAlbum.photos.map((photo, key) => (

                <Box sx={{ width: ['100%', '75%', '50%','25%'], px: 2, py: 2 }} key={key}>
                  <PhotoCard assetObj={photo} assetId={photo.id} name={ photo.name } href="#!" image={
                    (typeof photo.thumbnail !== 'undefined')?photo.thumbnail.key.replace(/protected\//, ''):require(`../@geocine/gatsby-theme-organization/assets/shotwire-logo.png`) 
                  }>
            
                  </PhotoCard>
                </Box>

      //           <div>
      //           <S3Image 
      //   key={photo.thumbnail.key} 
      //   // imgKey={photo.thumbnail.key.replace(/protected\//, '')}  
      //   imgKey={'thumbs/' + photo.thumbnail.key.replace(/.+thumbs\//, '')}
      //   // imgKey={photo.thumbnail.key}               
      //   // level="private"
      //   level="protected"
      //   // identityId={appsContext.state.uIdentityIdS3}//{photo.thumbnail.key.split("/")[1]}// this identity for tester works, shows protect image need to get or store this info somehow
      //   style={{display: 'inline-block', 'paddingRight': '5px'}}
      //   // onClick={ e => handlePhotoClick(e,photo) } // this is setState within a render and cannot be done and causees infinite loop
      // />
      // {photo.name} <br />
     
      // </div>
              )
          )
          :
          <span
            style={{ color:'white' }}
          >No Photos.</span>
        }

        </Row>
          </Container>
         
          <Container>
            <Row>
              <Col sm={12} lg={8}>              
                

               
                <div sx={{padding:'10px'}}>
                {
                  // <Button  sx={{ marginBottom: '2px' }} variant="dark" href="#!" size="md" onClick={() => {
                  //                   const frame = captureVideoFrame(playerDiv.current.querySelector('video').getInternalPlayer())
                  //                   console.log('captured frame', frame)
                  //                   // this.setState({ image: frame.dataUri })
                  //                 }}>Capture Frame</Button>
                }
                 {               
                                 // <Button sx={{ marginBottom: '2px' }} variant="dark" href="#!" size="md" onClick={e => handleClick(e, 'videoProblem')}>
                                 //         <FontAwesomeIcon
                                 //           icon={faVideoSlash}
                                 //           size="lg"
                                 //           sx={{
                                 //             // marginRight: '0.5rem'
                                 //           }}
                                 //         />{''}
                                         
                                 //       </Button>
                 }

                </div>

                <Row>
                 <Col sm={12} lg={12} className="d-md-none d-lg-none  d-xl-none" style={{textAlign:'center'}}>
                 
                  </Col>
                  <Col sm={12} lg={12} className="d-none d-md-block d-lg-block d-xl-block">
                   <div sx={{
                    paddingTop:'15px',
                    width:'1024px',
                    height:'100px',
                    textAlign:'left'
                    // border:'1px solid white'
                    // paddingLeft: '10px',
                    // paddingTop: '20px'
                   }}>

                   </div>
                  </Col>
                 </Row>
              

                <div>
                <br />
                <h6 className="text-uppercase mb-4 font-weight-bold">Licensing Contact</h6>
                      <p>
                        <FontAwesomeIcon
                              icon={faEnvelope}
                              size="sm"
                              sx={{
                                marginRight: '1.5rem'
                              }}
                            /> sales@shotwire.com</p>
                      <p>
                        <FontAwesomeIcon
                              icon={faPhone}
                              size="sm"
                              sx={{
                                marginRight: '0.5rem'
                              }}
                            /> +1 424.229.2118<br /> Santa Monica, CA Office</p>
                      {
                        // <p>
                        // <FontAwesomeIcon
                        //       icon={faPhone}
                        //       size="sm"
                        //       sx={{
                        //         marginRight: '0.5rem'
                        //       }}
                        //     /> +44 (0) 20 7421 6000<br /> UK Office (Avalon)</p>
                       }
                       <p>
                         Licensing video clips<br />
When you download a video clip from Shotwire, you are not buying the file itself — you are buying a license that allows you use the (Non-watermarked) content in your project(s).
                       </p>
                </div>
               { 
                //                <div>
               
                // <a target="_blank" href="https://www.amazon.com/b/?rh=i:instant-video,n:2858778011&ie=UTF8&filterId=OFFER_FILTER=SUBSCRIPTIONS&node=2858778011&ref_=assoc_tag_ph_1465430649312&_encoding=UTF8&camp=1789&creative=9325&linkCode=pf4&tag=shotwire-20&linkId=d94982fb94456b83ef30aa311c1a8bfc">Join Prime Video Channels Free Trial</a><img src="//ir-na.amazon-adsystem.com/e/ir?t=shotwire-20&l=pf4&o=1" width="1" height="1" border="0" alt="" style={{border:"none !important", margin:"0px !important"}} />
                //          <br />
                //          <a target="_blank" href="https://www.amazon.com/gp/video/primesignup?ref_=assoc_tag_ph_1402131641212&_encoding=UTF8&camp=1789&creative=9325&linkCode=pf4&tag=shotwire-20&linkId=35fd298d2c405e13d24d807b8cbe4c8b">Join Amazon Prime - Watch Thousands of Movies & TV Shows Anytime - Start Free Trial Now</a><img src="//ir-na.amazon-adsystem.com/e/ir?t=shotwire-20&l=pf4&o=1" width="1" height="1" border="0" alt="" style={{border:"none !important", margin:"0px !important"}} />
                      
                // </div>
              }

              </Col>
              <Col sm={12} lg={4}>
              
                  
                    <div sx={{
                        width: '100%',
                        padding: '10px',
                        // backgroundColor: '#444'
                      }}
                      >
                   
                    <h4>Photo Set Details</h4>
                    <b>Name:</b> {appsContext.state.photoAlbum.name}
                    <div sx={{
                            paddingTop: '0.5rem'
                          }}>
{ 
  // (appsContext.state.videoPlayer.hrDlSrc === '' || typeof appsContext.state.videoPlayer.hrDlSrc === 'undefined') ? 
  (appsContext.state.isAdmin===true || appsContext.state.isEditor===true 
    // || (appsContext.state.videoPlayer.assetObj.uploader ===  ( appsContext.state.user!==null ? appsContext.state.user.username:null )  ) 
    ) ?

        (

        <div>
        <Button 
          sx={{ marginBottom: '2px' }} 
          variant="dark" size="md" 
          // href={ appsContext.state.videoPlayer.hrDlSrc } 
          onClick={e => handleClick(e, 'showDownloadsModal')}
        >
                        <FontAwesomeIcon
                          icon={faDownload}
                          size="lg"
                          sx={{
                            marginRight: '0.5rem'
                          }}
                        />{' '}
                        Download                        
                        { 
                          // Math.round(appsContext.state.videoPlayer.fileSize) 
                        }
                         
                      </Button>
                      <br />
      <Button 
          sx={{ marginBottom: '2px' }} 
          variant="dark" size="md" 
          // href={ appsContext.state.videoPlayer.hrDlSrc } 
          onClick={e => handleClick(e, 'showEmbedModal')}
        >
                      <FontAwesomeIcon
                          icon={faLink}
                          size="lg"
                          sx={{
                            marginRight: '0.5rem'
                          }}
                        />{' '}Embed 
                        </Button>
          </div>
          
        )
           :

        (
          <div sx={{
            marginBottom: '2px'
           }} >
           <Button variant="dark" size="md">Sign up or Log in to Download or Embed this Video</Button>
          </div>

        )
    }
    {' '}
    <Button sx={{ marginBottom: '2px' }} variant="dark" href="" size="md" onClick={e => handleClick(e, 'addFavorite')}>
                        {
                        //  appsContext.state.favorites!==null ? (appsContext.state.favorites.includes(appsContext.state.videoPlayer.assetObj.id)===true) ?
                        // <FontAwesomeIcon
                        //   id={'fav_'+ appsContext.state.videoPlayer.assetObj.assetId}
                        //   icon={faStar}
                        //   size="lg"
                        //   sx={{
                        //     color:'gold'                            
                        //   }}                        
                        // />:
                        // <FontAwesomeIcon
                        //   id={'fav_'+ appsContext.state.videoPlayer.assetObj.assetId}
                        //   icon={faStar}
                        //   size="lg"
                        //   sx={{
                        //     color:'white'                            
                        //   }}                        
                        // />:
                        // <FontAwesomeIcon
                        //   id={'fav_'+ appsContext.state.videoPlayer.assetObj.assetId}
                        //   icon={faStar}
                        //   size="lg"
                        //   sx={{
                        //     color:'white'                            
                        //   }}                        
                        // />

                        } Add to favorites
                      </Button>
                      {' '}
                      <br />
                    {   
                      // <Button sx={{ marginBottom: '2px' }} variant="dark" href="" size="md" onClick={e => handleClick(e, 'addToCart')}>
                       
              //              appsContext.state.shopCart!==null ? (!appsContext.state.shopCart.includes(appsContext.state.videoPlayer.assetObj.id)) ?
              //                                   <span sx={{color: 'white'}}>
              //                                   <FontAwesomeIcon
              //                                     icon={faCartPlus}
              //                                     size="lg"
              //                                     sx={{
              //                                       marginRight: '0.5rem'
              //                                     }}
              //                                   />{' '}
              //                                   Add to Cart
              //                                   </span>
              //                           :
              //                           <span sx={{
              //   // marginRight: '0.5rem'
              //   // color: (appsContext.state.favorites.includes(assetObj.id)===true) ? 'gold':'white'
              //   color: 'gold'
              // }}>
              //                           <FontAwesomeIcon
              //                                     icon={faCartArrowDown}
              //                                     size="lg"
              //                                     sx={{
              //                                       marginRight: '0.5rem'
              //                                     }}
              //                                   />{' '}
              //                                   In Your Cart
              //                                   </span> : <span sx={{color: 'white'}}>
              //                                   <FontAwesomeIcon
              //                                     icon={faCartPlus}
              //                                     size="lg"
              //                                     sx={{
              //                                       marginRight: '0.5rem'
              //                                     }}
              //                                   />{' '}
              //                                   Add to Cart
              //                                   </span>
                        // </Button>
                        }
                      
                      {
                        // <div>
                        //   <a class="buy-with-crypto"
                        //      href="https://commerce.coinbase.com/checkout/7f7adf5b-9989-4bd7-b604-ee5846449208">
                        //     Buy with Crypto
                        //   </a>
                        //   <script src="https://commerce.coinbase.com/v1/checkout.js?version=201807">
                        //   </script>
                        // </div>
                      }
                      {
                        //https://commerce.coinbase.com/checkout/d9c8b11c-7d69-4b32-9aa8-8e13df5653f0 //$200 film-tv license
                        //https://commerce.coinbase.com/checkout/d001ec79-7f09-4882-9df0-ffa25077e946 //$600 game media license
                        }
                       
                     {
                                             // <CoinbaseCommerceButton 
                                             //     className="btn btn-dark" 
                                             //     // style={{backgroundColor:'#000fff'}} 
                                             //     checkoutId={'7f7adf5b-9989-4bd7-b604-ee5846449208'} 
                                             //     customMetadata={'Product ID: '+ appsContext.state.videoPlayer.assetObj.id} 
                                             // />
                                           }
                      {' '}
                      <Button sx={{ marginTop: '2px',marginBottom: '2px' }} variant="dark" href="#!" size="md" onClick={e => handleClick(e, 'VideoSubscriptions')}>
                        <FontAwesomeIcon
                          icon={faPiggyBank}
                          size="lg"
                          sx={{
                            marginRight: '0.5rem'
                          }}
                         
                        />{' '}
                        Buy Subscription Package
                      </Button>
                      {' '}
                      
</div>
                    
                    
                    <br />                   
                    {
                           // (appsContext.state.videoPlayer.assetObj.headline !== null) ? 
                           // ( appsContext.state.videoPlayer.assetObj.headline.match(/(swift)|(taylor)/ig) ) ? <iframe src="//rcm-na.amazon-adsystem.com/e/cm?o=1&p=12&l=ur1&category=primemusic&banner=1AKPX3FSXCJ64S0YZW02&f=ifr&linkID=524fafba6677521334c3dafb6911a210&t=shotwire-20&tracking_id=shotwire-20" width="300" height="250" scrolling="no" border="0" marginWidth="0" style={{border:"none"}} frameBorder="0"></iframe>
                           //      :<iframe src="//rcm-na.amazon-adsystem.com/e/cm?o=1&p=12&l=ur1&category=musicandentertainmentrot&f=ifr&linkID=6ae6b120e8379331742ac5973a1813ea&t=shotwire-20&tracking_id=shotwire-20" width="300" height="250" scrolling="no" border="0" marginWidth="0" style={{border:"none"}} frameBorder="0"></iframe>
                    
                           //      :         
                           //      <iframe src="//rcm-na.amazon-adsystem.com/e/cm?o=1&p=12&l=ur1&category=musicandentertainmentrot&f=ifr&linkID=6ae6b120e8379331742ac5973a1813ea&t=shotwire-20&tracking_id=shotwire-20" width="300" height="250" scrolling="no" border="0" marginWidth="0" style={{border:"none"}} frameBorder="0"></iframe>
                    
                                        
                                        }
                                         {
                                          // <br />
                                          //                                       <iframe src="//rcm-na.amazon-adsystem.com/e/cm?o=1&p=22&l=ur1&category=beauty&banner=04XCSE6T0AFM7MK75482&f=ifr&linkID=51d8d678fd6eaf62ab27ac5d595c6ce1&t=shotwire-20&tracking_id=shotwire-20" width="250" height="250" scrolling="no" border="0" marginWidth="0" style={{border:"none"}} frameBorder="0"></iframe>
                                          //                     <br />
                                          //                     <iframe src="//rcm-na.amazon-adsystem.com/e/cm?o=1&p=42&l=ur1&category=beauty&banner=0BEE8NNQHPRVWHT1G102&f=ifr&linkID=3645c25cbefde5a9bfbd2105667ec435&t=shotwire-20&tracking_id=shotwire-20" width="234" height="60" scrolling="no" border="0" marginWidth="0" style={{border:"none"}} frameBorder="0"></iframe>
                                                              }
                     
                      <div sx={{
                        width: '100%',
                        padding: '0px',
                        paddingBottom: '20px'
                      }}>


                      
                      { 
 //                        appsContext.state.videoPlayer.isDownloading ? (
 //          <div sx={{
 //  paddingRight: '10px',
 // }} >
 //          <FontAwesomeIcon
 //            icon={faSpinner}
 //            size="sm"
 //            sx={{
 //              // marginRight: '0.5rem'
 //            }}
 //            spin
 //          />
 //          </div>
 //        ) : (
 //          <div sx={{
 //  padding: '0px',
 // }}></div>
 //        )
    }

    
                      </div>
                    </div>
                  
                  
{
  //metaform here
}
                    { 
                      (appsContext.state.isAdmin===true || appsContext.state.isEditor===true 
                        // || (appsContext.state.videoPlayer.assetObj.uploader ===  ( appsContext.state.user!==null ? appsContext.state.user.username:null )  ) 
                        ) ? 
                      (
                        <div sx={{
                          width: '100%',
                          padding: '10px',

                        }}>
                        <h4>Meta / IPTC</h4>
                        {
                        // <MetaEditForm/>
                        }

            <Form sx={{
            padding: '5px'
            }} 
            onSubmit={e => handleSubmit(e)}>
            
            <Form.Row>
            <Form.Group controlId="setDateYear">
              <Form.Label>Created Date (Year </Form.Label>
              <Form.Control as="select" value={(appsContext.state.metaForm.yr!=null)?appsContext.state.metaForm.yr:''}  onChange={e => handleOnchange(e)}>
          
          {
            Array(30).fill().map((_, i) => (

              <option key={2020-i}>{2020-i}</option>

              ))
          }
          
            </Form.Control>
            </Form.Group>

            <Form.Group controlId="setDateMonth">
              <Form.Label>/ Month</Form.Label>
              <Form.Control as="select" value={(appsContext.state.metaForm.mo!=null)?appsContext.state.metaForm.mo:''}  onChange={e => handleOnchange(e)}>
          {
            Array(12).fill().map((_, i) => (

              <option key={("0" + (i+1)).slice(-2)}>{("0" + (i+1)).slice(-2)}</option>

              ))
          
          }
              
            </Form.Control>
            </Form.Group>

            <Form.Group controlId="setDateDay">
              <Form.Label>/ Day )</Form.Label>
              <Form.Control as="select"  value={(appsContext.state.metaForm.day!=null)?appsContext.state.metaForm.day:''}  onChange={e => handleOnchange(e)}>
          {
            Array(31).fill().map((_, i) => (

             <option key={("0" + (i+1)).slice(-2)}>{("0" + (i+1)).slice(-2)}</option>

              ))
          
          }
            </Form.Control>
            </Form.Group>
          </Form.Row>

        <Form.Group controlId="category">
            <Form.Label>Category</Form.Label>
            <Form.Control as="select" value={appsContext.state.metaForm.category} onChange={e => handleOnchange(e)}>
            <option>SELECT A CATEGORY</option>
        {
          Object.keys(categoryMap).map((keyName, i) => (

              <option value={keyName} key={keyName}>{categoryMap[keyName]}</option>

            ))

        }
        </Form.Control>
        </Form.Group>

        <Form.Group controlId="archived">
            <Form.Label>Archived / Published</Form.Label>
            <Form.Control as="select" value={appsContext.state.metaForm.archived} onChange={e => handleOnchange(e)}>
        {
          Object.keys(formArchivedMap).map((keyName, i) => (

              <option value={keyName} key={keyName}>{formArchivedMap[keyName]}</option>

            ))

        }
        </Form.Control>
        </Form.Group>

        <Form.Group controlId="marketplace">
            <Form.Label>Privacy</Form.Label>
            <Form.Control as="select" value={appsContext.state.metaForm.marketplace} onChange={e => handleOnchange(e)}>
        {
          Object.keys(formMarketplaceMap).map((keyName, i) => (

              <option value={keyName} key={keyName}>{formMarketplaceMap[keyName]}</option>

            ))

        }
        </Form.Control>
        </Form.Group>

       
        {
          (appsContext.state.metaForm.category!='Ingest' && appsContext.state.metaForm.archived==0)?
          <Form.Group controlId="forceUnpublish">
            <Form.Check type="checkbox" label="Force Un-Publish" />
          </Form.Group>
          :
          <span></span>
        }
          <Button variant="primary" type="submit">
          {
            (appsContext.state.isMetaSaving==true) ? 
                        (<span> Saving Meta <FontAwesomeIcon
                          icon={faSpinner}
                          size="sm"
                          sx={{
                            // marginRight: '0.5rem'
                          }}
                          spin
                        /> </span>)
                        : 
                        ('Submit')
                      }
          </Button>
          </Form> 

            

                        </div>
                      )
                      :
                        <span></span>
                    }


                  

                  

              </Col>
            </Row>
          </Container>
        {
          // works in localhost dev, but breaks in production deployment. dont know why
        // <DownloadsModal
        //             show={appsContext.state.downloadsModalShow}
        //             onHide={() => appsContext.dispatch({type:"SHOWDOWNLOADSMODAL",downloadsModalShow:false})}
        //           />
              }

              {
          // works in localhost dev, but breaks in production deployment. dont know why
        // <EmbedModal
        //             show={appsContext.state.embedModalShow}
        //             onHide={() => appsContext.dispatch({type:"SHOWEMBEDMODAL",embedModalShow:false})}
        //           />
              }
           
           </Layout>
           )

       
         
        
  }else{
    return(
          <Layout>
          <Container>

          { 
          <h3>No Photo Set</h3> 
        }
          <div sx={{
  padding: '10px',
 }} >
 <Spinner animation="border" variant="secondary" />
        {  
          // <FontAwesomeIcon
          //   icon={faSpinner}
          //   size="2x"
          //   sx={{
          //     // marginRight: '0.5rem'
          //   }}
          //   spin
          // />
        }
          </div>
          </Container>
          </Layout>
          )
  }

}else{
        return(
          <Layout>
          <Container>

          { <h3>No Video Info</h3> }
          
          </Container>
          </Layout>
          )
}

 
}


export default PhotoSetView