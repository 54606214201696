import React, { useEffect } from 'react'
/** @jsx jsx */
import { Cache } from "aws-amplify"
import { S3Image } from 'aws-amplify-react';
import { Link, navigate } from 'gatsby'

import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'
import styled from '@emotion/styled'
import { jsx } from 'theme-ui'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHeart, faStar, faEdit, faInfoCircle } from "@fortawesome/free-solid-svg-icons";

import Image from 'react-bootstrap/Image'

import awsconfig from "../../../aws-exports";
import { AppsContext } from '../../../AppsProvider'

const StyledCard = styled(Card)`
  
  background-color: rgba(0, 0, 0, 0.05);
  height: 100%;
  width: 200px;
`
/*box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.05);*/

 // state={{ assetId: assetId, assetName: name, image: image, assetObj: assetObj }}
const PhotoCard = ({ assetObj, assetId, name, href, image, children }) => {
  let isAdmin = false, userInfo = null, uRoles = null, favs = []

  // console.log('assetObj:', assetObj)

  const appsContext = React.useContext(AppsContext)

  // if(appsContext.state.user){
  //   userInfo  = appsContext.state.user
  //   // console.log('userInfo: ', userInfo)

  //   uRoles = userInfo.attributes[`custom:roles`].split(',')
  //   // console.log('uRoles: ', uRoles)
  //   if( uRoles.some(r => ['videoAdmin','superAdmin'].indexOf(r) >= 0) ) isAdmin = true
  //   // console.log('isAdmin:', isAdmin)
  
  //   favs = Cache.getItem('favorites')
  //   // console.log('favs>', favs)

  // }

  const vPlayer = {}
// console.log('appsContext.state.favorites:', appsContext.state.favorites)
  // console.log('assetObj:',assetObj.id)
  function getItemCacheCallback(assetId){
    return([assetId])
  }

  const handleClick = (e, arg) => {
    if (e){ 
           
      if(arg === 'playVideo'){
          e.preventDefault(); 
        let qstr = ''
          // vPlayer.videoUrl = '' 
          // vPlayer.signedUrl = ''
          // console.log('assetObj.thumbnailUrl:',assetObj.thumbNailUrl)
          vPlayer.image = (assetObj.thumbNailUrl != null)?assetObj.thumbNailUrl:image
          vPlayer.assetObj= assetObj 
          appsContext.dispatch({ type: "VPLAYER", vPlayer });
          // if(e.currentTarget.id === 'playButton'){
            if(assetObj.realSetId){
              qstr = "arsid="+assetObj.realSetId+"&asd="+assetObj.setDate+"&an="+assetObj.name
            }else qstr = "aid="+assetObj.id
            navigate(
              "/VideoPlayer/?"+qstr,
              // {
              //   state: { searchform },
              // }
            )
          // } 
      }else if(arg === 'addFavorite'){
        e.preventDefault(); 
        // console.log('target:', e.target)
        // console.log('favorite id:',assetId)
        // const favs = Cache.getItem('favorites', { callback: getItemCacheCallback(assetId)} )
        let favs = Cache.getItem('shotwire-favorites')

        // console.log('favs-', favs)
        const expiration = new Date(2020, 12, 18)
        
        if(favs == null){
          // console.log('set item...')
          Cache.setItem('shotwire-favorites', [ assetObj.id ], { expires: expiration.getTime() });
          appsContext.dispatch({type:"FAVORITES", favorites:[ assetObj.id ]})
          document.getElementById("fav_"+assetObj.id).style.color = "gold";
        }else{
          
          if(!favs.includes(assetObj.id)){
            favs.push(assetObj.id)
            // e.target.style.color = 'red'
            document.getElementById("fav_"+assetObj.id).style.color = "gold";
            // document.getElementById("fav_"+assetId).style.backgroundColor = "lightblue";
            Cache.setItem('shotwire-favorites', favs, { expires: expiration.getTime() })
            appsContext.dispatch({type:"FAVORITES", favorites:favs})           
            // console.log('added to favs.')
            // console.log('favs.', favs)
          }else{
            // e.target.style.color = 'white'
            document.getElementById("fav_"+assetObj.id).style.color = "white";
            // document.getElementById("fav_"+assetId).style.backgroundColor = "pink";
            const tmp = favs.filter(item => item !== assetObj.id)
            // console.log('tmp:', tmp)
            Cache.setItem('shotwire-favorites', tmp, { expires: expiration.getTime() })
            favs = Cache.getItem('shotwire-favorites')
            appsContext.dispatch({type:"FAVORITES", favorites:favs})
            // console.log('removed from favs.')
            // console.log('favs.:', favs)
         }
          
        }
        // console.log('appsContext.state.favorites=',appsContext.state.favorites)
      }

    }
  }


  return (
    <StyledCard bg="" text="">
      {
              // <Card.Img variant="top" src={'thumbs/' + assetObj.thumbnail.key.replace(/.+thumbs\//, '')} />
              }
        {
          (typeof assetObj.thumbnail !== 'undefined')?
              <S3Image 
                key={assetObj.thumbnail.key} 
                // imgKey={photo.thumbnail.key.replace(/protected\//, '')}  
                imgKey={'thumbs/' + assetObj.thumbnail.key.replace(/.+thumbs\//, '')}
                // imgKey={photo.thumbnail.key}               
                // level="private"
                level="protected"
                // identityId={appsContext.state.uIdentityIdS3}//{photo.thumbnail.key.split("/")[1]}// this identity for tester works, shows protect image need to get or store this info somehow
                style={{with: '100%', display: 'inline-block', 'paddingRight': '5px', 'objectFit':'cover'}}
                // onClick={ e => handlePhotoClick(e,photo) } // this is setState within a render and cannot be done and causees infinite loop
              />
              :
              <span></span>
              }
        {
          
          // <Link to="/VideoPlayer" onClick={e => handleClick(e, 'playVideo')}>
          //     <Image 
          //       src={image}
          //       alt={name}
          //       height={168}
          //       // width={295}
          //       width={'100%'}
          //       // width={ (assetId=='none') ? `300` : '100%'}
          //       // fluid
          //     />
          // </Link>
        }
        <Card.ImgOverlay sx={{color:"white",textAlign:"right"}}>
       
        <Button size="sm" id={`playButton`+assetObj.id} variant="secondary" onClick={e => handleClick(e, 'playVideo')} sx={{border:'1px solid rgba(255, 255, 255, .5)'}}>   
          <FontAwesomeIcon
            icon={faInfoCircle}
            size="sm"
            sx={{
              // marginRight: '0.5rem'
            }}
          />
        </Button>{' '}
        
        <Button size="sm" id={`favButton`+assetObj.id} variant="secondary" onClick={e => handleClick(e, 'addFavorite')} sx={{border:'1px solid rgba(255, 255, 255, .5)'}}>
          {
            // (appsContext.state.favorites.includes(assetObj.id)===true) ? 
            <FontAwesomeIcon
              id={'fav_'+assetObj.id}
              icon={faStar}
              size="sm"
              sx={{
                // marginRight: '0.5rem'
                color:  (appsContext.state.favorites!==null) ? (appsContext.state.favorites.includes(assetObj.id)===true) ? 'gold':'white': 'white'
              
              }}
            />
            // :
            // <FontAwesomeIcon
            //   id={'fav_'+assetObj.id}
            //   icon={faStar}
            //   size="sm"
            //   sx={{
            //     // marginRight: '0.5rem'
            //     color: 'white'
            //   }}
            // />
          }

        </Button> 

        </Card.ImgOverlay>
        {  
          <Card.Body sx={{
                backgroundColor:'rgb(27, 37, 48)',
                color:'rgba(255,255,255, .7)'
            }}>
          
           <Card.Title>
           {name}
           </Card.Title>
          <Card.Text>
          {children}
          </Card.Text>   
          </Card.Body>
        }
  
    </StyledCard>

)
// sx={{backgroundColor:"rgba(0, 0, 0, .3)"}}
}
/*
 <Image 
                  src={require(`${image}`)}
                  alt="avalon-pacificcoastnews-video"
                  height={168}
                  width="100%"
                  rounded
                />
/*
 <FontAwesomeIcon
          icon={faVideo}
          size="lg"
          sx={{
            marginRight: '0.5rem'
          }}
        />
        */

export default PhotoCard
